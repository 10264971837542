export const french_translations = {
  value: 'FR',
  content: {
    Global_Meta_Description: 'Bienvenue sur Lilenek, votre marketplace où les entreprises (PME) et institutions africaines bénéficient mutuellement des meilleurs biens et expertises, pour le plaisir des consommateurs à travers le monde.',
    Global_Status: 'statut',
    Global_Button_View: 'Voir',
    Global_Button_Add: 'Ajouter',
    Global_Button_Add_Cover: 'Ajouter une couverture',
    Global_Button_Add_Logo: 'Ajouter un logo',
    Global_Button_Add_PDF: 'Ajouter un PDF',
    Global_Button_Add_IMG_File: 'Ajouter un fichier IMG',
    Global_Button_Resize: "Cliquez sur l'image pour redimensionner",
    Global_Button_Cancel: 'Annuler',
    Global_Button_Save_Updates: 'Enregistrer',
    Global_Button_Crop: 'Recadrer',
    Global_Button_Close: 'Fermer',
    Global_Button_Ratio: "L'image ne respecte pas le rapport hauteur/largeur souhaité",
    Global_Button_Delete: 'Supprimer',
    Global_Button_Reset: 'Réinitialiser',
    Global_Button_Share: 'Partager',
    Global_Button_Save_Translation: 'Enregistrer la traduction',
    Global_Button_Loading: 'chargement...',
    Global_Button_Loading_Wait: 'chargement, veuillez patienter...',
    Global_Button_Load_More: 'voir plus',
    Global_Button_See_More: 'voir plus',
    Global_Button_See: 'voir',
    Global_Button_See_Less: 'voir moins',
    Global_Button_Follow: 'Suivre',
    Global_Button_Unfollow: 'Ne plus suivre',
    Global_Button_Next: 'Suivant',
    Global_Button_Back: 'Précédent',
    Global_Button_Done: 'Terminé',
    Global_Address_Title_1: "Titre de l'adresse",
    Global_Address_Title_2: 'Siège social de, Bureau de, Adresse de, etc...',
    Global_Address_Street_Name_1: 'Nom de la rue',
    Global_Address_Street_Name_2: '83 rue de la paix',
    Global_Address_Complement_1: 'Nom de la rue (complément)',
    Global_Address_Complement_2: 'Complément du nom de la rue',
    Global_Address_Complement_3: 'Appartement, suite, bâtiment, étage, etc...',
    Global_Address_Postcode_1: 'Code postal',
    Global_Address_Postcode_2: '75000',
    Global_Address_City_1: 'Ville',
    Global_Address_City_2: 'Ville de la paix',
    Global_Address_County_1: 'Comté',
    Global_Address_County_2: 'Comté de la paix',
    Global_Address_State_1: 'État',
    Global_Address_State_2: 'État de la paix',
    Global_Address_Country: 'Pays',
    Global_Address_GPS_1: "Veuillez localiser l'adresse ci-dessus sur la carte.",
    Global_Address_GPS_2: 'Cliquez sur le marqueur pour le déplacer et soyez aussi précis que possible !',
    Global_Pin_SMS_1: 'Bonjour',
    Global_Pin_SMS_2: 'Votre code de vérification Lilenek est :',
    Global_Activate_Email_Label_1: 'Vous devez activer votre email',
    Global_Activate_Email_Label_2: "en utilisant l'email de vérification que nous vous avons envoyé.",
    Global_Activate_Email_Button_1: "Envoi de l'email",
    Global_Activate_Email_Button_2: 'ENVOYÉ',
    Global_Activate_Email_Button_3: 'RENVOYER',
    // APP COMPONENT
    App_User: 'Utilisateur',
    App_Purchases: 'Achats',
    App_Contacts: 'Messagerie',
    App_Dashboard: 'Tableau de bord',
    App_New_Post: 'Nouveau post',
    App_Cart: 'Panier',
    App_Home: 'Accueil',
    App_Signup: 'Inscription',
    App_Login: 'Connexion',
    App_Add_PWA_Content: 'Télécharger Lilenek!',
    App_Add_PWA_Safari_Tap: 'Appuyez',
    App_Add_PWA_Safari_Content: `et "Ajouter à l'écran d'accueil"`,
    App_Add_PWA: 'Télécharger',
    // COMPONENT SWITCHLANGUAGEBUTTON
    SwitchLanguageButton_Button_Title: 'Langues',
    SwitchLanguageButton_Title: 'Sélectionner une langue',
    SwitchLanguageButton_Current_Lang: 'Langue actuelle',
    // COMPONENT SEARCHENGINEMODAL
    SearchEngineModal_Title: 'Rechercher',
    SearchEngineModal_Item: 'Article/Service',
    SearchEngineModal_User: 'Utilisateur',
    SearchEngineModal_Company: 'Entreprise',
    SearchEngineModal_Job: 'Mission',
    SearchEngineModal_Opportunity: 'Opportunité',
    SearchEngineModal_Marketing: 'Marketing',
    SearchEngineModal_Filters: 'Filtres',
    SearchEngineModal_Write_Keyword: 'Écrire un mot-clé...',
    SearchEngineModal_Looking_For: 'Je recherche...',
    SearchEngineModal_Looking_For_From_Country: 'au',
    SearchEngineModal_Write_Username: "Écrire un nom d'utilisateur...",
    SearchEngineModal_Write_CompanyName: "Écrire un nom d'entreprise...",
    SearchEngineModal_Filters_Country: 'Pays',
    SearchEngineModal_Filters_Type: 'Type',
    SearchEngineModal_Filters_Industry: 'Industrie',
    SearchItems_Not_Found: 'Aucun élément trouvé',
    SearchItems_Not_Found_In: 'au',
    SearchUser_Not_Found: 'Aucun utilisateur trouvé',
    SearchUser_Follows_You: 'vous suit',
    SearchCompanies_Not_Found: 'Aucune entreprise trouvée',
    SearchCompanies_Subcategory: 'Sous-catégorie',
    SearchCompanies_Category: 'Catégorie',
    SearchJobMission_Not_Found: 'Aucun emploi',
    SearchOpportuniy_Not_Found: 'Aucune opportunité',
    SearchCompanies_Advanced_Search: 'Recherche avancée',

    // TOPBAR COMPONENT
    TopBar_Login: 'Connexion',
    TopBar_Signup: 'Inscription',
    // COMPONENT TOUR
    Company_Tour_1_1: "Allez sur 'Modifier",
    Company_Tour_1_2: 'pour ajouter vos produits/services',
    Company_Tour_2: "puis 'Créer un portefeuille' pour recevoir/faire des paiements",
    Profile_Tour_1: "Options est l'endroit où vous pouvez voir la plupart des fonctionnalités dont vous disposez en tant qu'utilisateur, de la modification de votre profil à la création d'un profil d'entreprise, en passant par la publication d'une opportunité commerciale, d'un Flex amusant et même de la mise en location d'un objet. Et bien d'autres fonctionnalités à venir!",
    Profile_Tour_2: "Followers/Following est assez explicite, vous pouvez suivre d'autres utilisateurs ou être suivi par eux",
    Profile_Tour_3: "Entreprises C'est la liste des entreprises que vous avez créées, essayez d'appuyer sur ce bouton",
    Profile_Tour_4: 'Deals est lorsque 2 ou plusieurs parties effectuent un achat de plusieurs produits/services selon des termes spécifiques, vous pouvez le voir comme une innovation dans les transactions en ligne',
    Profile_Tour_5: "Connexions est l'endroit où votre entreprise est suivie par d'autres entreprises",
    Profile_Tour_6: 'Expériences est une liste de vos expériences professionnelles sur Lilenek, cela sera utile pour les futurs recruteurs',
    // UPLOADIMGFEATURE COMPONENT
    UploadIMGFeature_Media_Type: 'photo',
    // FOOTER COMPONENT
    Footer_All_Rights: 'Tous les droits sont réservés.',
    Footer_Legal_Mentions: 'Mentions légales',
    Footer_Legal_Mentions_Content: `
**1. Introduction:**
   - "Bienvenue sur Lilenek, votre porte d'entrée vers le Marché Commun Africain, où les opportunités commerciales abondent. À Lilenek, nous sommes passionnés par la création de connexions qui permettent aux PME, aux institutions et aux entrepreneurs de prospérer ensemble. Notre mission est d'élever la réputation de l'Afrique en tant que fournisseur mondial de biens de haute qualité et d'expertise, unissant ceux qui aspirent à faire la différence. Nous vous invitons à nous rejoindre dans cette aventure passionnante alors que nous travaillons ensemble comme jamais auparavant."

**2. Vision et Mission :**
   - Vision de Lilenek :
     - "Nous croyons en un Marché Commun Africain où les PME et les institutions peuvent mutuellement bénéficier des meilleurs biens et de l'expertise de chacun. L'Afrique fournit déjà les meilleurs biens au monde, ensemble, faisons passer cela au niveau supérieur et relions les personnes qui aspirent à faire la différence. Ensemble comme jamais auparavant."
   - Déclaration de mission :
     - "Lilenek vise à créer un écosystème sûr et transparent où les entreprises africaines du continent, les entrepreneurs africains de la diaspora et les entreprises internationales peuvent se connecter et mener des affaires en toute confiance."

**3. Déclaration de Problème :**
   - "Consciente de la nécessité de services B2B efficaces et d'une infrastructure pour soutenir la croissance du marché commercial africain, Lilenek s'engage à relever ce défi grâce à sa plateforme."

**4. Informations sur l'entreprise :**
   - "Lilenek, exploité par Africantum, est une entreprise enregistrée dont le siège principal est situé en France. Vous pouvez nous contacter à contact@thebhub.io."

**5. Conditions d'utilisation :**
   - En utilisant les services de Lilenek, vous acceptez de vous conformer aux Conditions d'utilisation suivantes, qui constituent un accord légalement contraignant entre vous et Africantum :
   
      **5.1. Gestion du compte et croissance de l'entreprise :**
         - "Votre compte Lilenek est la clé pour débloquer des opportunités sur le Marché Commun Africain. Nous proposons une suite complète d'outils et de fonctionnalités pour vous aider à gérer votre entreprise, à vous connecter avec des partenaires potentiels et à favoriser la croissance. En vous inscrivant et en utilisant votre compte, vous accédez à un monde de possibilités."
   
      **5.2. Comportement de l'utilisateur :**
         - Les utilisateurs doivent se conformer à toutes les lois et réglementations applicables.
         - Les activités interdites peuvent inclure la fraude, le harcèlement, le spam et toute activité illégale.
   
      **5.3. Soumission de contenu :**
         - Les utilisateurs sont responsables du contenu qu'ils soumettent sur la plateforme.
         - Le contenu interdit peut inclure du matériel protégé par des droits d'auteur, des discours haineux ou du contenu nocif.
   
      **5.4. Confidentialité et utilisation des données :**
         - Informations sur la collecte, le stockage et l'utilisation des données de l'utilisateur.
         - Consentement pour le traitement et le partage des données au sein de la plateforme.
   
      **5.5. Propriété intellectuelle :**
         - Protection des droits de propriété intellectuelle pour les utilisateurs et la plateforme.
         - Mécanisme de signalement des violations des droits d'auteur ou des marques de commerce.
   
      **5.6. Transactions et paiements :**
         - Modalités liées au traitement des paiements, y compris les frais, les remboursements et les méthodes de paiement.
         - Responsabilités du vendeur pour les descriptions précises des produits et la livraison.
   
      **5.7. Résolution des litiges :**
         - Procédures de résolution des litiges, y compris la médiation ou l'arbitrage.
         - Signalement et résolution des conflits entre les utilisateurs.
   
      **5.8. Résiliation et suspension :**
         - Conditions dans lesquelles les comptes peuvent être résiliés ou suspendus.
         - Processus d'appel pour les utilisateurs faisant l'objet de mesures concernant leur compte.
   
      **5.9. Responsabilité et garantie :**
         - Limitations de responsabilité pour la plateforme et ses utilisateurs.
         - Exclusions de garantie pour les produits ou services.
   
      **5.10. Droit applicable et juridiction :**
         - Choix de la loi et de la juridiction pour les questions juridiques.
   
      **5.11. Modifications des conditions :**
         - Le droit de la plateforme de mettre à jour ou de modifier les termes et conditions.
         - Notification aux utilisateurs des changements.
   
      **5.12. Indemnisation :**
         - Les utilisateurs acceptent d'indemniser la plateforme et de la protéger contre les réclamations ou les responsabilités légales.
   
      **5.13. Communication et notifications :**
         - Comment la plateforme communique avec les utilisateurs, y compris les notifications par e-mail.
   
      **5.14. Avis et commentaires des utilisateurs :**
         - Politiques liées aux avis et aux commentaires des utilisateurs sur la plateforme.
   
      **5.15. Mesures de sécurité :**
         - Responsabilités de l'utilisateur pour maintenir la sécurité du compte.
         - Mesures de la plateforme pour protéger les données des utilisateurs.
   
      **5.16. Accessibilité et disponibilité :**
         - Exigences d'accessibilité de la plateforme et garanties de disponibilité.
   
      **5.17. Droits de résiliation de l'utilisateur :**
         - Conditions dans lesquelles les utilisateurs peuvent résilier leurs comptes.
   
      **5.18. Directives de la communauté :**
         - Directives spécifiques à la plateforme et règles de conduite.
   
      **5.19. Conformité aux lois :**
         - Engagement de l'utilisateur à se conformer à toutes les lois applicables.
   
      **5.20. Divers :**
         - Toutes les clauses ou mentions légales supplémentaires spécifiques à la mission et à la base d'utilisateurs de la plateforme.

**6. Politique de confidentialité :**
   - "La Politique de confidentialité de Lilenek décrit comment nous collectons, stockons et traitons les données des utilisateurs conformément aux lois sur la protection des données. Cela inclut l'utilisation du cryptage pour protéger les données sensibles et l'accès restreint aux personnes autorisées uniquement."

**7. Responsabilités de l'utilisateur :**
   - "En tant qu'utilisateur de Lilenek, vous êtes responsable du respect des normes éthiques et légales dans vos interactions avec d'autres utilisateurs et au sein de la plateforme. Cela comprend l'abstention de toute activité frauduleuse, de toute escroquerie ou de tout comportement répréhensible."

**8. Propriété intellectuelle :**
   - "Tous les droits de propriété intellectuelle sur Lilenek, y compris, mais sans s'y limiter, les marques de commerce et les droits d'auteur, sont détenus par Africantum. Les utilisateurs doivent respecter ces droits, et toute violation signalée sera traitée rapidement."

**9. Résolution des litiges :**
   - "En cas de litiges, les utilisateurs sont encouragés à signaler les problèmes via les canaux désignés de Lilenek. Les litiges peuvent être résolus par la médiation ou l'arbitrage, comme indiqué dans notre processus de résolution des litiges."

**10. Juridiction et loi applicable :**
    - "La juridiction pour les questions légales liées à Lilenek est la France. Tout litige juridique sera régi par les lois de la France."

**11. Application et sanctions :**
    - "Lilenek se réserve le droit d'appliquer ces termes et politiques. Les violations peuvent entraîner des sanctions, y compris la suspension ou la résiliation du compte."

**12. Signalement de comportement répréhensible :**
    - "Si vous rencontrez des comportements répréhensibles, des escroqueries ou des activités frauduleuses sur Lilenek, veuillez les signaler immédiatement à notre équipe d'assistance. Nous prenons la protection de l'utilisateur au sérieux et enquêterons et traiterons de tels problèmes rapidement."

**13. Références légales et conformité :**
    - "Lilenek s'engage à se conformer à toutes les lois nationales et internationales applicables, notamment en matière de protection des consommateurs, de commerce électronique et de protection des données."

**14. Protection des données et confidentialité:**
     - "Lilenek attache de l'importance à la vie privée et à la protection des données de ses utilisateurs. Nous nous engageons à respecter les lois sur la protection des données, y compris le Règlement général sur la protection des données (RGPD) et toute autre réglementation applicable."
     - "Collecte des données de l'utilisateur : Nous collectons et traitons les données des utilisateurs à des fins spécifiques, notamment la gestion des comptes, la communication, le traitement des transactions, l'amélioration des services, les informations intelligentes sur le marché, la sécurité et la prévention de la fraude, ainsi que la personnalisation."
     - "Sécurité des données : Nous utilisons des mesures de sécurité robustes, notamment le cryptage, pour protéger les données des utilisateurs contre tout accès non autorisé ou toute violation de sécurité."
     - "Partage avec des tiers : Les données des utilisateurs peuvent être partagées avec des fournisseurs de services tiers de confiance à des fins telles que le traitement des paiements et les analyses. Nous nous assurons que ces fournisseurs respectent également les réglementations sur la protection des données."
     - "Droits de l'utilisateur : Les utilisateurs ont le droit d'accéder à, de rectifier ou de supprimer leurs données personnelles détenues par Lilenek. Les demandes peuvent être faites via nos canaux désignés."
     - "Cookies : Nous utilisons des cookies pour améliorer l'expérience de l'utilisateur. Les utilisateurs peuvent gérer les préférences en matière de cookies via les paramètres de leur navigateur."
     - "En utilisant les services de Lilenek, vous consentez à la collecte, au traitement et à l'utilisation de vos données aux fins spécifiées telles que décrites dans notre Politique de confidentialité."
    `,
    // LOGINPAGE
    LoginPage_Hello: 'Bonjour',
    LoginPage_Friend: "l'ami",
    LoginPage_Welcome_Back: 'Content de te revoir!',
    // LOGINFORM COMPONENT
    LoginForm_Login_Title: 'Connexion',
    LoginForm_Identifier: "Nom d'utilisateur / Email",
    LoginForm_Password: 'Mot de passe',
    LoginForm_Forgot_Password: 'Mot de passe oublié?',
    LoginForm_Turn_On_Location: 'Activez votre localisation pour entièrement sécuriser votre compte',
    LoginForm_Login: 'Connexion',
    LoginForm_Logging: 'Connexion en cours',
    LoginForm_No_Member_Yet: 'Pas encore membre?',
    LoginForm_Signup: "S'inscrire maintenant",
    LoginForm_Error_Login: 'Vous avez le mauvais identifiant/mot de passe, veuillez réessayer ou réinitialiser votre mot de passe',
    LoginForm_Reset_Password: 'Réinitialiser votre mot de passe',
    LoginForm_Remember_Password: 'Vous vous rappelez votre mot de passe?',
    LoginForm_Switch_User: 'Changer de compte',
    // FORGOTPASSWORDFORM COMPONENT
    ForgotPasswordForm_Identifier: 'Identifiant',
    ForgotPasswordForm_Email_Mobile: 'Email/Mobile',
    ForgotPasswordForm_Pin_Verification: 'Vérification du code PIN',
    ForgotPasswordForm_Please_Wait: "attendez s'il vous plaît...",
    ForgotPasswordForm_Sending_SMS: 'Envoi de SMS...',
    ForgotPasswordForm_Send_Via_Email: 'Envoyer le code PIN par e-mail',
    ForgotPasswordForm_Send_Via_SMS: 'Envoyer le code PIN par SMS',
    ForgotPasswordForm_Validation: 'Validation',
    ForgotPasswordForm_Is_Submitted: 'Votre mot de passe a été mis à jour, veuillez essayer de vous connecter!',
    ForgotPasswordForm_Login_Now: 'Connecte-toi',
    ForgotPasswordForm_Is_Updated: 'Votre mot de passe a été mis à jour!',
    ForgotPasswordForm_New_Password: 'Nouveau mot de passe',
    ForgotPasswordForm_New_Password_Confirmation: 'Confirmation du nouveau mot de passe',
    ForgotPasswordForm_Reset_Password: 'Réinitialiser le mot de passe',
    ForgotPasswordForm_Last_Step: 'Dernière étape',
    ForgotPasswordForm_Login_Button: 'Connexion',
    ForgotPasswordForm_Back_Button: 'Retour',
    ForgotPasswordForm_PIN_Verification_Button: 'Vérification du code PIN',
    ForgotPasswordForm_Continue_Button: 'Continuer',
    ForgotPasswordForm_Turn_On_Location: 'Activez votre localisation pour sécuriser entièrement votre compte',
    ForgotPasswordForm_Is_Email_Submitted: 'Votre email a été envoyé, veuillez vérifier votre boîte de réception',
    ForgotPasswordForm_Resend_Email: "Une erreur s'est produite. Veuillez réessayer",
    // SIGNUPPAGE COMPONENT
    SignUpPage_Welcome_To: 'Bienvenue sur',
    // SIGNUPFORM COMPONENT
    SignUpForm_Title: 'Inscription',
    SignUpForm_Account_Info: 'Informations du compte',
    SignUpForm_Username: "Nom d'utilisateur",
    SignUpForm_Username_Taken: 'est déjà utilisé',
    SignUpForm_Email_Already_Used: 'est déjà utilisé sur un autre compte',
    SignUpForm_Email_Incorrect: "Format de l'email est incorrect",
    SignUpForm_Email: 'Email',
    SignUpForm_Gender: 'Genre',
    SignUpForm_DOB: 'Date de naissance',
    SignUpForm_My_Gender: 'Mon pronom est...',
    SignUpForm_Firstname: 'Prénom',
    SignUpForm_Lastname: 'Nom de famille',
    SignUpForm_New_Password: 'Nouveau mot de passe',
    SignUpForm_Mobile_Verification: 'Vérification mobile',
    SignUpForm_Mobile: 'Mobile',
    SignUpForm_Sending_SMS: 'Envoi de SMS...',
    SignUpForm_Send_SMS: 'Envoyer',
    SignUpForm_SMS_Error: "Le SMS n'a pas pu être envoyé à",
    SignUpForm_Beta_Notice: 'VERSION BETA : Voici votre CODE PIN',
    SignUpForm_Use_International_Number: "Veuillez utiliser le format international '+' or '00'",
    SignUpForm_Great: 'Super!',
    SignUpForm_Next_Step: 'La prochaine étape sera la vérification de votre compte',
    SignUpForm_We_Sent_Email: "Après avoir appuyé sur 'Créer un compte', nous vous enverrons un e-mail pour vérifier votre compte à",
    SignUpForm_To_Verify: 'pour vérifier votre compte.',
    SignUpForm_Create_Account_Button: 'Créez votre compte',
    SignUpForm_Last_Step: 'Dernière étape',
    SignUpForm_Login_Button: 'Connexion',
    SignUpForm_Back_Button: 'Retour',
    SignUpForm_PIN_Verification_Button: 'Vérification du code PIN',
    SignUpForm_Continue_Button: 'Continuer',
    SignUpForm_Turn_On_Location: 'Activez votre localisation pour sécuriser entièrement votre compte',
    SignUpForm_Already_Member: 'Déjà membre?',
    SignUpForm_All_Done: 'Toutes les étapes sont terminées - vous avez reussi!',
    SignUpForm_Loading_message: "Creation de votre compte, patience s'il vous plaît...",
    // HOME COMPONENT
    Home_Title: 'Bienvenue sur',
    Home_Hello: 'Bonjour',
    Home_Friend: "l'ami",
    Home_Welcome: 'Bienvenue sur',
    Home_On_Lilenek: 'Lilenek',
    Home_A_New_XP: '#UneNouvelleExpérienceAfricaine',
    Home_Dashboard: 'Tableau de bord',
    Home_Latest_Companies: 'Dernières entreprises enregistrées',
    Home_Latest_Job_Missions: "Dernières missions d'emploi",
    Home_Latest_Articles: 'Derniers articles',
    Home_Latest_Services: 'Derniers services',
    Home_Latest_Sponsorships: 'Offres de sponsoring',
    Home_Latest_Discounts: 'Dernières offres de réductions',
    Home_Where_African_Companies: 'Votre marketplace où les entreprises et institutions africaines bénéficient mutuellement des meilleurs biens et expertises, pour le plaisir des consommateurs à travers le monde',
    Home_Explore_Now: 'Accédez gratuitement',
    Home_Join_Thousands: 'Rejoignez des milliers de professionnels africains, des partenaires commerciaux et les dernières opportunités entrepreneuriales!',
    Home_Tour_1: "Scan est l'endroit où vous pouvez créer votre portefeuille électronique et ajouter des méthodes de paiement pour envoyer/recevoir des paiements, gérer vos portefeuilles, accéder à des services financiers et consulter vos dernières transactions",
    Home_Tour_2: 'Recherche est la façon dont vous recherchez des produits/services, des entreprises ou des utilisateurs sur le marché africain, ainsi que des opportunités commerciales',
    Home_Tour_3: "Notifications est l'endroit où vous pouvez consulter vos dernières notifications reçues",
    Home_Tour_4: "Panier est l'endroit où vous ajoutez des produits/services à acheter maintenant ou ultérieurement",
    Home_Tour_5: "Tableau de bord est l'endroit où vous pouvez avoir un aperçu de toutes vos activités et des fonctionnalités que nous vous offrons",
    Home_Tour_6: "Fil d'actualité est l'endroit où vous pouvez consulter tous les derniers articles, les activités commerciales et les opportunités",
    // PROFILE COMPONENT
    Profile_Updated: 'Mise à jour réussie !',
    Profile_Help: 'Aide/Guide',
    Profile_Admin: 'Admin',
    Profile_Feedback: 'Recommendation/signaler',
    Profile_Share_Profile: 'Partager le profil',
    Profile_Dashboard: 'Tableau de bord',
    Profile_Edit: 'Modifier le profil',
    Profile_Settings: 'Paramètres',
    Profile_Share: 'Partager le profil',
    Profile_Followers: 'ABONNÉS',
    Profile_Flexs: 'FLEXS',
    Profile_Followings: 'ABONNEMENTS',
    Profile_Companies: 'ENTREPRISES',
    Profile_Deals: 'VENTES',
    Profile_Connections: 'CONNEXIONS',
    Profile_Button_Following: 'Abonnement en cours...',
    Profile_Button_Follow: 'Suivre',
    Profile_Button_Unfollowing: 'Désabonnement en cours...',
    Profile_Button_Unfollow: 'Ne plus suivre',
    Profile_Flex_Title: 'Flexs',
    Profile_Experiences_Title: 'Expériences',
    Profile_Opportunities_Title: 'Opportunités',
    Profile_Loading_Flexs: 'Chargement des flexs, veuillez patienter...',
    Profile_Time_To_Flex: "C'est le moment de Flex",
    Profile_Loading_Experiences: 'Chargement des expériences, veuillez patienter...',
    Profile_Exp_For: 'pour',
    Profile_Exp_At: 'chez',
    Profile_Exp_Present: 'Actuel',
    Profile_Work_Experiences_Title: 'Expériences professionnelles',
    Profile_Entrepreneurship_Title: 'Entrepreneuriat',
    Profile_Loading_Work_Experiences: 'Chargement des expériences professionnelles, veuillez patienter...',
    Profile_Loading_Entrepreneurship: 'Chargement des expériences en entrepreneuriat, veuillez patienter...',
    Profile_Take_Opportunity: 'Soyez le premier à saisir cette opportunité',
    Profile_Opportunity_1: 'personnes',
    Profile_Opportunity_2: 'personne',
    Profile_Opportunity_3: 'ont saisi cette opportunité',
    Profile_Loading_Opportunities: 'Chargement des opportunités, veuillez patienter...',
    Profile_New_Opportunity: 'Nouvelle opportunité',
    Profile_Send_Message: 'Envoyer un message',
    // SPENDINGCODEUPDATEMODAL COMPONENT
    SpendingCodeModal_Forgot_Spending_Code: 'Code de dépenses oublié?',
    SpendingCodeModal_Email_Sent: 'E-mail envoyé!',
    SpendingCodeModal_Remember_Spending_Code: 'Vous souvenez-vous de votre code de dépenses ? Cliquez ici',
    SpendingCodeModal_Identifier: 'Identifiant',
    SpendingCodeModal_Email_Mobile: 'Email/Mobile',
    SpendingCodeModal_Pin_Verification: 'Vérification du code PIN',
    SpendingCodeModal_Please_Wait: "attendez s'il vous plaît...",
    SpendingCodeModal_Sending_SMS: 'Envoi de SMS...',
    SpendingCodeModal_Send_Via_Email: 'Envoyer le code PIN par e-mail',
    SpendingCodeModal_Send_Via_SMS: 'Envoyer le code PIN par SMS',
    SpendingCodeModal_Validation: 'Validation',
    SpendingCodeModal_Is_Submitted: 'Votre mot de passe a été mis à jour, veuillez essayer de vous connecter!',
    SpendingCodeModal_Is_Updated: 'Votre mot de passe a été mis à jour!',
    SpendingCodeModal_New_Password: 'Nouveau mot de passe',
    SpendingCodeModal_New_Password_Confirmation: 'Confirmation du nouveau mot de passe',
    SpendingCodeModal_Reset_Password: 'Réinitialiser le mot de passe',
    SpendingCodeModal_Last_Step: 'Dernière étape',
    SpendingCodeModal_Login_Button: 'Connexion',
    SpendingCodeModal_Back_Button: 'Retour',
    SpendingCodeModal_PIN_Verification_Button: 'Vérification du code PIN',
    SpendingCodeModal_Continue_Button: 'Continuer',
    // SPENDINGCODEUPDATEMODAL COMPONENT
    TipBox_First: 'Soyez le premier à la prendre!',
    TipBox_Taken: 'pris par',
    // SENDRECOMMENDATIONMODAL COMPONENT
    SendRecommendationModal_Do_You_Want: 'Parce que les bons amis partagent de bons conseils',
    SendRecommendationModal_We_Add_Them: 'Parlez de Lilenek à vos collègues, partenaires commerciaux et amis, afin qu’ils puissent eux aussi profiter de cette nouvelle expérience africaine.',
    SendRecommendationModal_Send_Button: 'Envoyer',
    SendRecommendationModal_Email_Button: 'E-mail',
    SendRecommendationModal_Press_Enter: 'Appuyez sur Entrée pour en ajouter davantage.',
    // DASHBOARD COMPONENT
    Dashboard_Notifications_Title: 'Notifications',
    Dashboard_My_Business_Title: 'Activités pro',
    Dashboard_Merchandises_Title: 'Marchandises',
    Dashboard_Partnerships_Title: 'Partenariats',
    Dashboard_Investments_Title: 'Investissements',
    Dashboard_Market_Analytics_Title: 'Analyse du Marché',
    Dashboard_Collaborations_Title: 'Collaborations',
    Dashboard_Business_Flexs_Title: 'Marketing',
    Dashboard_Last_Viewed_Title: 'Derniers Articles Consultés',
    Dashboard_Favorites_Title: 'Favoris',
    Dashboard_Job_Missions_Title: 'Missions Pro',
    Dashboard_New_Company: 'Nouvelle Entreprise',
    Dashboard_Ship_Package: 'Expédier un colis',
    Dashboard_New_Discount: 'Nouvelle remise',
    Dashboard_Overview_Title: 'Vue globale',
    Dashboard_Latest_Sells_Title: 'Dernières ventes',
    Dashboard_Calendar_Title: 'Prestations réservées',
    Dashboard_Audio_Order_Title: 'Dernières commandes audio',
    Dashboard_Preview_Staffs_1: 'employés',
    Dashboard_Preview_Staffs_2: 'Postes vacants',
    Dashboard_Preview_Supplychains_1: "Chaînes d'approvisionnement",
    Dashboard_Preview_Supplychains_2: "Souhaitez-vous avoir une visibilité sur les fournisseurs qui composent votre chaîne d'approvisionnement?",
    Dashboard_Preview_Supplychains_3: "Ajoutez votre chaîne d'approvisionnement",
    Dashboard_Preview_Supplychains_4: 'SAIN',
    Dashboard_Preview_Supplychains_5: 'EXCELLENTE',
    Dashboard_Preview_Supplychains_6: 'AVERTISSEMENT',
    Dashboard_Preview_Supplychains_7: 'DANGER',
    Dashboard_Preview_Sells_1: 'Dernières ventes',
    Dashboard_Preview_Sells_2: 'EN ATTENTE',
    Dashboard_Preview_Sells_3: 'NON APPROUVÉ',
    Dashboard_Preview_Sells_4: 'EN COURS',
    Dashboard_Preview_Sells_5: 'LIVRAISON',
    Dashboard_Preview_Sells_6: 'VENTE ANNULÉE',
    Dashboard_Preview_Sells_7: 'PAIEMENT EN RETARD',
    Dashboard_Preview_Sells_8: 'FERMÉ',
    Dashboard_Preview_Sells_9: 'EN ATTENTE',
    Dashboard_Preview_Sells_10: 'NON APPROUVÉ',
    Dashboard_Preview_Sells_11: 'EN COURS',
    Dashboard_Preview_Sells_12: 'LIVRAISON',
    Dashboard_Preview_Sells_13: 'VENTE ANNULÉE',
    Dashboard_Preview_Sells_14: 'PAIEMENT EN RETARD',
    Dashboard_Preview_Sells_15: 'FERMÉ',
    Dashboard_Preview_Job_Missions_1: 'Missions professionnelles',
    Dashboard_Preview_Job_Missions_2: 'En attente',
    Dashboard_ListStockManagement_1: 'Articles',
    Dashboard_ListStockManagement_2: 'Services',
    Dashboard_ListStockManagement_3: "Appels d'offres",
    Dashboard_ListStockManagement_4: 'Locations',
    Dashboard_ListStockManagement_5: 'Stocks',
    Dashboard_ListStockManagement_6: 'Entrepôts',
    Dashboard_ListStockManagement_7: 'Remises',
    // COMPONENT LISTDISCOUNTS
    ListDiscounts_Add_Discounts_Button: 'Créé une remise',
    ListDiscounts_No_Discount: 'Aucune remise pour le moment',
    ListDiscounts_Edit_Discount: 'Modifier remise',
    // COMPONENT LISTPRODUCTS
    ListProducts_Add_Articles_Button: 'Ajouter des articles',
    ListProducts_No_Articles: 'Aucun article pour le moment',
    ListProducts_See_Article: "voir l'article",
    // COMPONENT LISTSERVICES
    ListServices_Add_Services_Button: 'Ajouter un service',
    ListServices_No_Services: 'Aucun service pour le moment',
    ListServices_See_Service: 'voir le service',
    // COMPONENT LISTTENDERS
    ListTenders_Add_Tenders_Button: "Ajouter un appel d'offres",
    ListTenders_No_Tenders: "Aucun appel d'offres pour le moment",
    ListTenders_See_Tender: "voir l'appel d'offres",
    // COMPONENT LISTRENTALS
    ListRentals_Add_Rentals_Button: 'Ajouter une location',
    ListRentals_No_Rentals: 'Aucune location pour le moment',
    ListRentals_See_Rental: 'voir la location',
    // COMPONENT LISTSTOCKS
    ListStocks_Add_Stocks_Button: 'Créer un stock',
    ListStocks_No_Stocks: 'Aucun stock pour le moment',
    ListStocks_See_Stock: 'voir le stock',
    ListStocks_Services_Title: 'Services',
    ListStocks_Articles_Title: 'Articles',
    ListStocks_Products_Title: 'Produits',
    ListStocks_Sells_Title: 'Dernières ventes des 7 derniers jours',
    // COMPONENT LISTWAREHOUSES
    ListWarehouses_Add_Warehouses_Button: 'Créer un entrepôt',
    ListWarehouses_No_Warehouses: 'Aucun entrepôt pour le moment',
    ListWarehouses_See_Warehouse: "voir l'entrepôt",
    ListWarehouses_Services_Title: 'Services',
    ListWarehouses_Articles_Title: 'Articles',
    ListWarehouses_Products_Title: 'Produits',
    ListWarehouses_Total_Stocks_Title: 'Stocks totaux',
    // COMPONENT PARTNERSHIP
    Partnership_Title_1: "Pourquoi ? Comment ? Qu'est-ce qu'un partenariat ?",
    Partnership_Title_2: 'Partenariats disponibles',
    Partnership_1: 'Fintech Partnership',
    Partnership_2: "Fintech Partnership pour les propriétaires d'entreprises Fintech et les institutions financières",
    Partnership_3: 'Retail Investor',
    Partnership_4: "Retail Investor pour tous les propriétaires d'entreprises",
    Partnership_5: 'Logistics Partnership',
    Partnership_6: "Logistics Partnership pour les propriétaires d'entreprises de logistique et de transport",
    Partnership_7: 'Health Partnership',
    Partnership_8: 'Health Partnership pour les institutions et professionnels de la santé',
    Partnership_9: 'Security Partnership',
    Partnership_10: 'Security Partnership pour les institutions et professionnels de la sécurité',
    Partnership_11: 'Talent Resources Partnership',
    Partnership_12: 'Talent Resources Partnership pour les institutions et professionnels des ressources humaines',
    Partnership_13: 'Risks Management Partnership',
    Partnership_14: 'Risks Management Partnership pour les institutions et professionnels de la gestion des risques',
    Partnership_15: 'Fivezer Partnership',
    Partnership_16: 'Fivezer Partnership pour les propriétaires de Five-aside',
    Partnership_17: 'Auditor Partnership',
    Partnership_18: "Auditor Partnership pour les institutions et professionnels de l'audit",
    Partnership_19: "En tant que propriétaire d'entreprise, votre ambition est de faire prospérer votre entreprise ; mais élargir votre portée de marché peut être difficile et prendre du temps. C'est pourquoi nous avons conçu des fonctionnalités qui vous permettent de vous associer à Lilenek et de vous offrir des opportunités uniques pour toucher un public plus large sans effort.",
    Partnership_20: 'Pour être éligible à un partenariat, votre entreprise doit :',
    Partnership_21: 'ÉTAPE #1',
    Partnership_22: 'être vérifiée dans le cadre de notre programme de sécurité et de diligence raisonnable (KYC), puis nous vous informerons de votre éligibilité par e-mail/notification.',
    Partnership_23: 'ÉTAPE #2',
    Partnership_24: "Ajouter l'une des sous-catégories suivantes à vos sous-catégories d'entreprise.",
    Partnership_25: 'Commencer',
    Partnership_26: 'Vous devez avoir au moins une méthode de paiement sur le portefeuille de',
    Partnership_27: "'s",
    Partnership_28: 'portefeuille électronique',
    Partnership_29: 'Vous devez ajouter la SOUS-CATÉGORIE sur laquelle vous souhaitez commencer un partenariat avec',
    Partnership_30: '.',
    Partnership_31: "Si vous l'avez déjà fait, notre équipe évaluera votre entreprise et vous contactera bientôt.",
    Partnership_32: "Commodity Compliance Partnership pour aider les entreprises à exporter vers d'autres marchés",
    Partnership_33: 'Commodity Compliance Partnership',

    // COMPANYCARD COMPONENT
    CompanyCard_Add_Company_1: 'Découvrez tout ce que le marché africain a à offrir et plus encore!',
    CompanyCard_Add_Company_2: 'Ajoutez votre entreprise',
    CompanyCard_Followers: 'Abonnés',
    CompanyCard_Followings: 'Abonnements',
    CompanyCard_Staffs: 'Effectif',
    CompanyCard_Total_Deals: 'Ventes',
    CompanyCard_Flex_Items: 'Articles Flex',
    CompanyCard_Supplychains: "Chaînes d'Approv.",
    CompanyCard_Switch_Company: "changer d'entreprise",
    // NEWDEAL COMPONENT
    Deal_Legal_Mentions_Content: `    
     **Accord d'utilisation de la plateforme**
     
     Veuillez lire attentivement le présent Accord d'utilisation de la plateforme. En cochant la case ci-dessous, vous reconnaissez avoir lu, compris et accepté de respecter les termes et conditions énoncés dans cet accord.
     
     **1. Objectif de la Plateforme**
     La Plateforme sert de facilitateur de communication et de place de marché connectant les Acheteurs et les Fournisseurs pour divers biens et services. La Plateforme offre un espace permettant aux Parties de conclure des transactions commerciales, mais elle n'assume pas la responsabilité de la qualité, de la légalité ou de la livraison des produits ou services échangés entre les Acheteurs et les Fournisseurs.
     
     **2. Responsabilités de la Plateforme**
     La Plateforme s'engage à :
     
     2.1. Fournir une plateforme permettant aux Acheteurs et aux Fournisseurs d'interagir et de conclure des transactions.
     
     2.2. Maintenir la fonctionnalité de la plateforme et s'assurer qu'elle est accessible aux Acheteurs et aux Fournisseurs.
     
     **3. Responsabilités de l'Acheteur et du Fournisseur**
     3.1. L'Acheteur et le Fournisseur reconnaissent qu'ils sont seuls responsables de leurs actions et transactions sur la Plateforme.
     
     3.2. L'Acheteur est responsable de :
     
        a. L'exactitude de toutes les informations fournies sur la Plateforme.
        
        b. Effectuer la diligence raisonnable sur les Fournisseurs avant de conclure des transactions.
        
        c. Le paiement des produits ou services conformément aux termes convenus avec le Fournisseur.
     
     3.3. Le Fournisseur est responsable de :
     
        a. L'exactitude de toutes les informations fournies sur la Plateforme.
        
        b. L'exécution des commandes et la livraison des produits ou services conformément aux accords conclus avec l'Acheteur.
        
        c. La résolution de tout litige avec l'Acheteur de manière équitable et en temps opportun.
     
     **4. Règlement des litiges**
     En cas de litiges ou de désaccords entre l'Acheteur et le Fournisseur, les Parties conviennent de résoudre de tels litiges de manière indépendante, sans impliquer la Plateforme. La Plateforme ne sera pas tenue responsable des litiges ou des pertes découlant des transactions effectuées sur la Plateforme.
     
     **5. Limitation de responsabilité**
     La Plateforme ne sera pas responsable des dommages directs, indirects, accessoires, spéciaux, consécutifs ou exemplaires, y compris, mais sans s'y limiter, la perte de bénéfices, la réputation, les données ou l'utilisation, même si elle est informée de la possibilité de tels dommages, résultant de ou liés à l'utilisation de la Plateforme.
     
     **6. Indemnisation**
     L'Acheteur et le Fournisseur acceptent d'indemniser et de dégager la Plateforme de toute réclamation, demande ou dommage (y compris les frais juridiques) découlant de leur utilisation de la Plateforme et de tout litige ou transaction entre eux.
     
     **7. Résiliation**
     La Plateforme se réserve le droit de résilier ou de suspendre l'accès d'un utilisateur à la plateforme à sa discrétion, avec ou sans motif.
     
     **8. Modifications de l'accord**
     La Plateforme peut mettre à jour le présent Accord à tout moment. Les utilisateurs sont encouragés à le consulter périodiquement.
     
     **9. Droit applicable**
     Le présent Accord sera régi et interprété conformément aux lois de la République de Maurice. Tout litige ou réclamation découlant de ou en relation avec le présent Accord sera soumis à la juridiction exclusive des tribunaux de la République de Maurice.
     
     En cochant la case ci-dessous, j'accepte les termes et conditions de l'Accord d'utilisation de la Plateforme tel qu'énoncés ci-dessus :     
     `,
    // NEWPRODUCTMODAL COMPONENT
    NewProductModal_Title: "Nouveau lot d'articles",
    NewProductModal_As: 'pour',
    NewProductModal_Name: 'Nom',
    NewProductModal_Brand: 'Marque',
    NewProductModal_Category: 'Catégorie',
    NewProductModal_SubCategories: 'Sous-catégories',
    NewProductModal_SubCategories_Placeholder: "Écrivez le nom d'une sous-catégorie",
    NewProductModal_SubCategories_Help: 'Appuyez sur ENTRÉE pour ajouter une sous-catégorie personnalisée',
    NewProductModal_Descriptions_Title: 'Descriptions (multi-langues)',
    NewProductModal_Descriptions_Label: 'Langues disponibles (africaines et internationales)',
    NewProductModal_Descriptions_Placeholder: 'Sélectionnez une langue',
    NewProductModal_Add_Language: 'traduction',
    NewProductModal_Selected_Description_Label_1: 'Descriptions actuelles',
    NewProductModal_Selected_Description_Label_2: 'et',
    NewProductModal_Selected_Description_Label_3: 'descriptions',
    NewProductModal_Selected_Description_Label_4: 'description',
    NewProductModal_Selected_Description_Label_5: 'avec un contenu vide',
    NewProductModal_Selected_Description_Placeholder: 'Sélectionnez une description',
    NewProductModal_Selected_Description_Caption: '(contenu vide)',
    NewProductModal_Selected_Description_Content: 'Description en',
    NewProductModal_Selected_Description_Content_Warning: "N'oubliez pas de sauvegarder votre contenu après chaque mise à jour",
    NewProductModal_Pictures_Title: 'Images',
    NewProductModal_Article_Composition_Title: "Composition de l'article",
    NewProductModal_Product_Name_1: "Nom de l'article ou",
    NewProductModal_Product_Name_2: 'nom du produit',
    NewProductModal_Reference: "Référence/ID de l'article",
    NewProductModal_Article_Unit_Title: "Unité de l'article",
    NewProductModal_Product_Amount_Unit_1: 'Quantité de',
    NewProductModal_Product_Amount_Unit_2: 'par',
    NewProductModal_Product_Unit_1: 'Unité',
    NewProductModal_Product_Unit_2: "Sélectionnez une unité d'article",
    NewProductModal_Size_Helper_1: 'Taille universelle, unités de taille pour vêtements et chaussures',
    NewProductModal_Size_Helper_2: 'basées sur des tailles internationales (source)',
    NewProductModal_Sizes_Title: 'Tailles',
    NewProductModal_Product_Measurements_Unit: "Mesures de l'article",
    NewProductModal_Product_Width: 'Largeur',
    NewProductModal_Product_Height: 'Hauteur',
    NewProductModal_Product_Length: 'Longueur',
    NewProductModal_Weight_Title: 'Poids',
    NewProductModal_Weight: "Unité de poids de l'article",
    NewProductModal_Weight_Unit_1: 'Poids de',
    NewProductModal_Weight_Unit_2: 'article',
    NewProductModal_Price_Title_1: 'Prix (par',
    NewProductModal_Price_Title_2: 'article',
    NewProductModal_Value_1: 'Coût de',
    NewProductModal_Value_2: 'taille',
    NewProductModal_Value_3: 'unité/',
    NewProductModal_Currency: 'Devise',
    NewProductModal_Amount_Stock_Title: 'Quantité (en stock)',
    NewProductModal_Amount_Stock_1: 'Quantité de',
    NewProductModal_Amount_Stock_2: 'article',
    NewProductModal_Button_Confirm_Article: "Confirmer l'article",
    NewProductModal_All_Articles_Title: 'Tous les articles',
    NewProductModal_No_Articles: 'Aucun article pour le moment',
    NewProductModal_Storage_Title: 'Stockage',
    NewProductModal_Storage_Subtitle_1: 'Ajoutez vos',
    NewProductModal_Storage_Subtitle_2: 'articles',
    NewProductModal_Storage_Subtitle_3: 'article',
    NewProductModal_Storage_Subtitle_4: 'dans quel stock?',
    NewProductModal_Stock_In: 'Stocker dans',
    NewProductModal_Button_Create_Articles: 'Créer des articles',
    NewProductModal_Total_Worth: 'Valeur totale',
    // NEWSERVICEMODAL COMPONENT
    NewServiceModal_Title: 'Nouveau service',
    NewServiceModal_As: 'en tant que',
    NewServiceModal_Name: 'Nom',
    NewServiceModal_Brand: 'Marque',
    NewServiceModal_Category: 'Catégorie',
    NewServiceModal_SubCategories: 'Sous-catégories',
    NewServiceModal_SubCategories_Placeholder: "Écrivez le nom d'une sous-catégorie",
    NewServiceModal_SubCategories_Help: 'Appuyez sur ENTREE pour ajouter une sous-catégorie personnalisée',
    NewServiceModal_Duration_Title: 'Durée (facultatif)',
    NewServiceModal_Duration: 'Durée',
    NewServiceModal_Duration_Unit: 'Unité de temps',
    NewServiceModal_Duration_Helper_1: "Tous les services n'ont pas une durée fixe, mais vous pouvez définir une durée moyenne pour",
    NewServiceModal_Duration_Helper_2: 'votre service',
    NewServiceModal_Price_Title: 'Prix',
    NewServiceModal_Price_Value_1: 'Coût de',
    NewServiceModal_Price_Value_2: 'votre service',
    NewServiceModal_Price_Currency: 'Devise',
    NewServiceModal_Descriptions_Title: 'Descriptions (multilingues)',
    NewServiceModal_Descriptions_Label: 'Langues disponibles (africaines et internationales)',
    NewServiceModal_Descriptions_Placeholder: 'Sélectionnez une langue',
    NewServiceModal_Add_Language: 'traduction',
    NewServiceModal_Selected_Description_Label_1: 'Descriptions actuelles',
    NewServiceModal_Selected_Description_Label_2: 'et',
    NewServiceModal_Selected_Description_Label_3: 'descriptions',
    NewServiceModal_Selected_Description_Label_4: 'description',
    NewServiceModal_Selected_Description_Label_5: 'avec un contenu vide',
    NewServiceModal_Selected_Description_Placeholder: 'Sélectionnez une description',
    NewServiceModal_Selected_Description_Caption: '(contenu vide)',
    NewServiceModal_Selected_Description_Content: 'Description en',
    NewServiceModal_Selected_Description_Content_Warning: "N'oubliez pas de sauvegarder votre contenu après chaque mise à jour",
    NewServiceModal_Pictures_Title: 'Images',
    NewServiceModal_Locations_Title: 'Emplacement(s)',
    NewServiceModal_Availablility: 'Disponible à',
    NewServiceModal_Availablility_Placeholder: "Écrivez le nom d'un emplacement",
    NewServiceModal_Availablility_Helper: 'Emplacement depuis lequel votre service est livré.',
    NewServiceModal_Button_Create_Service: 'Ajouter le service',
    // NEWALERTMODAL COMPONENT
    NewAlertModal_Title: 'Nouvelle alerte',
    NewAlertModal_As: 'en tant que',
    NewAlertModal_Name: 'Titre',
    NewAlertModal_Status: 'Status',
    NewAlertModal_SubCategories: 'Sous-catégories',
    NewAlertModal_SubCategories_Placeholder: 'Écrire un nom de sous-catégorie',
    NewAlertModal_SubCategories_Help: 'Appuyez sur ENTRÉE pour ajouter une sous-catégorie personnalisée',
    NewAlertModal_Additional_Info: 'Informations supplémentaires',
    NewAlertModal_Level: 'Niveau',
    NewAlertModal_Accuracy: 'Précision',
    NewAlertModal_Radius: 'Rayon en km',
    NewAlertModal_Descriptions_Title: 'Descriptions (multilingues)',
    NewAlertModal_Descriptions_Label: 'Langues disponibles (africaines et internationales)',
    NewAlertModal_Descriptions_Placeholder: 'Sélectionnez une langue',
    NewAlertModal_Add_Language: 'traduction',
    NewAlertModal_Selected_Description_Label_1: 'Descriptions actuelles',
    NewAlertModal_Selected_Description_Label_2: 'et',
    NewAlertModal_Selected_Description_Label_3: 'descriptions',
    NewAlertModal_Selected_Description_Label_4: 'description',
    NewAlertModal_Selected_Description_Label_5: 'avec un contenu vide',
    NewAlertModal_Selected_Description_Placeholder: 'Sélectionnez une description',
    NewAlertModal_Selected_Description_Caption: '(contenu vide)',
    NewAlertModal_Selected_Description_Content: 'Description en',
    NewAlertModal_Selected_Description_Content_Warning: "N'oubliez pas de sauvegarder votre contenu après chaque mise à jour",
    NewAlertModal_Pictures_Title: 'Images',
    NewAlertModal_Button_Create_Alert: "Publier l'alerte",

    // COMPONENT NEWTENDERMODAL
    NewTenderModal_Title: "Nouvel Appel d'Offres",
    NewTenderModal_Introduction: `
    Un appel d'offres vous donne l'opportunité d'exprimer TOUS les problèmes/besoins de votre organisation.
    
    Avec ces informations, notre système vous fournira des solutions sous forme de notifications en fonction du type de votre besoin, au meilleur coût possible.
    
    Il est important que vous fournissiez autant de détails que possible dans les descriptions ci-dessous, que vous pouvez également traduire dans différentes langues.
    `,
    NewTenderModal_Name: 'Titre',
    NewTenderModal_Type: 'Type',
    NewTenderModal_Subcategories_1: 'Mots-clés spécifiques à',
    NewTenderModal_Subcategories_2: "votre appel d'offres",
    NewTenderModal_Subcategories_3: 'Écrire des mots-clés...',
    NewTenderModal_Subcategories_4: 'Appuyez sur ENTREE pour ajouter une sous-catégorie personnalisée',
    NewTenderModal_Start_Date: 'Date de début',
    NewTenderModal_End_Date: 'Date de fin',
    NewTenderModal_Descriptions_Title: 'Descriptions (multi-langues)',
    NewTenderModal_Cost_Title: 'Coût (environ)',
    NewTenderModal_Cost_1: 'Coût de',
    NewTenderModal_Cost_2: "votre appel d'offres",
    NewTenderModal_Currency: 'Devise',
    NewTenderModal_Pictures_Title: 'Images',
    NewTenderModal_Documents_Title: 'Documents',
    NewTenderModal_References_Title: 'Recherche de référence(s)',
    NewTenderModal_References_Subtitle: "Attacher des références à votre appel d'offres aide à identifier votre besoin avec des éléments tangibles.",
    NewTenderModal_References_1: 'Produits ou services',
    NewTenderModal_References_2: 'Écrire un nom de référence...',
    NewTenderModal_Selected_References_Title: 'Référence(s) sélectionnée(s)',
    NewTenderModal_No_References: 'Aucune référence sélectionnée pour le moment',
    NewTenderModal_Button_New_Tender: "Soumettre l'Appel d'Offres",
    NewTenderModal_Total_Worth: 'Valeur totale',
    // COMPONENT NEWRENTALMODAL
    NewRentalModal_Title: 'Nouvelle Location',
    NewRentalModal_As: 'en tant que',
    NewRentalModal_Renter: 'Locataire',
    NewRentalModal_Name: 'Nom',
    NewRentalModal_Asset: 'Actif',
    NewRentalModal_New_Asset: 'Nouvel actif',
    NewRentalModal_Type: 'Type',
    NewRentalModal_Asset_Name: 'Nom',
    NewRentalModal_Brand: 'Marque',
    NewRentalModal_Category: 'Catégorie',
    NewRentalModal_Subcategories_1: 'Sous-catégories',
    NewRentalModal_Subcategories_2: 'Appuyez sur ENTREE pour ajouter une sous-catégorie personnalisée',
    NewRentalModal_Subcategories_3: "Écrire le nom d'une sous-catégorie",
    NewRentalModal_Product_Unit_Amount_1: 'Quantité de',
    NewRentalModal_Product_Unit_Amount_2: 'par',
    NewRentalModal_Unit_1: 'Unité',
    NewRentalModal_Unit_2: 'Tailles universelles, unités de tailles de vêtements et chaussures',
    NewRentalModal_Unit_3: 'basées sur les tailles internationales (source)',
    NewRentalModal_Sizes_Title: 'Tailles',
    NewRentalModal_Article_Measurements: "Mesures de l'article",
    NewRentalModal_Product_Width: 'Largeur',
    NewRentalModal_Product_Height: 'Hauteur',
    NewRentalModal_Product_Length: 'Longueur',
    NewRentalModal_Weight_Title: 'Poids',
    NewRentalModal_Product_Article_Weight_Unit: "Unité de poids de l'article",
    NewRentalModal_Product_Weight_1: 'Poids de',
    NewRentalModal_Product_Weight_2: "l'article",
    NewRentalModal_Original_Price_Title: "Prix d'origine",
    NewRentalModal_Original_Price: "Prix d'origine de",
    NewRentalModal_Original_Price_Currency: 'Devise',
    NewRentalModal_Warehouse_Second_Street_Name_1: 'Adresse de la rue (complément)',
    NewRentalModal_Warehouse_Second_Street_Name_2: "Complément de l'adresse de rue de l'entrepôt",
    NewRentalModal_Warehouse_Second_Street_Name_3: 'Appartement, suite, bâtiment, étage, etc...',
    NewRentalModal_Warehouse_Postcode_1: 'Code postal',
    NewRentalModal_Warehouse_Postcode_2: "Code postal de l'entrepôt",
    NewRentalModal_Warehouse_City_1: 'Ville',
    NewRentalModal_Warehouse_City_2: "Ville de l'entrepôt",
    NewRentalModal_Warehouse_County_1: 'Comté',
    NewRentalModal_Warehouse_County_2: "Comté de l'entrepôt",
    NewRentalModal_Warehouse_State_1: 'État',
    NewRentalModal_Warehouse_State_2: "État de l'entrepôt",
    NewRentalModal_Warehouse_Country_1: 'Pays',
    NewRentalModal_Duration_Title: 'Durée (facultatif)',
    NewRentalModal_Duration: 'Durée',
    NewRentalModal_Duration_Unit: 'Unité de temps',
    NewRentalModal_Duration_Info_1: 'Quelle unité de temps facturez-vous',
    NewRentalModal_Duration_Info_2: 'votre location',
    NewRentalModal_Rental_Price_Title: 'Prix de la location',
    NewRentalModal_Price_Currency: 'Devise',
    NewRentalModal_Descriptions_Title: 'Descriptions (multi-langues)',
    NewRentalModal_Pictures_Title: 'Images',
    NewRentalModal_Locations_Title: 'Emplacement(s)',
    NewRentalModal_Pickup_Location: 'Emplacement de prise en charge',
    NewRentalModal_Dropping_Location_1: 'Emplacement(s) de dépôt',
    NewRentalModal_Dropping_Location_2: "Écrire le nom d'un emplacement",
    NewRentalModal_Dropping_Location_3: 'Emplacement où votre location doit être déposée.',
    NewRentalModal_Add_Rental: 'Ajouter la location',
    // COMPONENT FORMNEWSTOCK
    FormNewStock_New_Stock_Title: 'Nouveau Stock',
    FormNewStock_Name: 'Nom',
    FormNewStock_Owner: 'Propriétaire du stock',
    FormNewStock_Warehouse: "Stock dans l'entrepôt",
    FormNewStock_Manager: 'Gestionnaire du stock',
    FormNewStock_Create_Stock: 'Créer le stock',
    FormNewStock_Create_Stock_Modal_1: 'Nouveau stock pour',
    FormNewStock_Create_Stock_Modal_2: 'Êtes-vous satisfait de votre nouveau stock',
    FormNewStock_Create_Stock_Modal_3: 'et',
    FormNewStock_Create_Stock_Modal_4: 'en tant que gestionnaire de stock?',
    FormNewStock_Create_Stock_Modal_5: 'Oui, soumettre',
    FormNewStock_Create_Stock_Modal_6: 'Non, revenir en arrière',
    FormNewCompany_Title: 'Créer votre entreprise',
    FormNewCompany_Subtitle_1: 'Félicitations',
    FormNewCompany_Subtitle_2: 'pour votre initiative de faire passer votre entreprise au niveau supérieur. Les étapes suivantes sont simples et modifiables ultérieurement.',
    FormNewCompany_Email_Not_Verifed_1: "Avant de commencer ce parcours avec nous, vous devez confirmer votre adresse e-mail via l'e-mail que nous vous avons envoyé à",
    FormNewCompany_Email_Not_Verifed_2: "Si c'est fait, veuillez recharger cette page.",
    FormNewCompany_Missing_Fields: 'Vous avez omis de remplir un champ obligatoire. Veuillez remplir tous les champs requis.',
    FormNewCompany_Step_1_Title: 'Informations de base',
    FormNewCompany_Step_1_Intro: "Parlez-nous de votre entreprise. Nous vous conseillons d'être aussi précis que possible, la transparence est un argument fort pour attirer plus de clients.",
    FormNewCompany_Step_1_Name_1: "Nom de l'entreprise",
    FormNewCompany_Step_1_Name_2: "Nom de l'entreprise",
    FormNewCompany_Step_1_Name_3: 'Le nom doit comporter au moins 4 caractères',
    FormNewCompany_Step_1_Name_4: 'Vous ne devez utiliser que des lettres, des chiffres ou des traits de soulignement',
    FormNewCompany_Step_1_Name_5: 'est déjà pris',
    FormNewCompany_Step_1_Label_1: 'Enseigne',
    FormNewCompany_Step_1_Label_2: "Écrivez l'enseigne de votre entreprise",
    FormNewCompany_Step_1_Label_3: "L'enseigne doit comporter au moins 4 caractères",
    FormNewCompany_Step_1_Label_4: 'Il y a un caractère non autorisé dans',
    FormNewCompany_Step_1_Juridical_Status_1: 'Statut juridique',
    FormNewCompany_Step_1_Juridical_Status_2: 'SAS, SARL, Limited, etc...',
    FormNewCompany_Step_1_Juridical_Status_3: 'Le status doit comporter au moins 2 caractères',
    FormNewCompany_Step_1_Juridical_Status_4: 'Il y a un caractère non autorisé dans',
    FormNewCompany_Step_1_Category: 'Catégorie',
    FormNewCompany_Step_1_Subcategory_1: 'Sous-catégories',
    FormNewCompany_Step_1_Subcategory_2: 'Écrire un nom de sous-catégorie',
    FormNewCompany_Step_1_Subcategory_3: 'Appuyez sur ENTRÉE pour ajouter une sous-catégorie personnalisée',
    FormNewCompany_Step_1_Email_1: 'E-mail',
    FormNewCompany_Step_1_Email_2: "Écrivez l'e-mail de votre entreprise",
    FormNewCompany_Step_1_Email_3: 'Votre e-mail professionnel ne peut pas être le même que votre e-mail personnel.',
    FormNewCompany_Step_1_Owner_Title: "Propriétaire(s) de l'entreprise",
    FormNewCompany_Step_1_Owner_Subtitle: 'Votre entreprise doit avoir au moins un propriétaire',
    FormNewCompany_Step_1_Owner_1: "Propriétaire(s) existant(s) de l'entreprise",
    FormNewCompany_Step_1_Owner_2: 'Écrire un nom',
    FormNewCompany_Button_New_Owner: 'Ajouter non-existant propriétaire',
    FormNewCompany_Button_Existing_Owner: 'Ajouter propriétaire existant',
    FormNewCompany_Button_Add_Owner: 'Ajouter le propriétaire',
    FormNewCompany_Step_1_Registration_1: "Numéro d'enregistrement de l'entreprise",
    FormNewCompany_Step_1_Registration_2: "Écrivez le numéro d'enregistrement de votre entreprise",
    FormNewCompany_Step_1_Registration_3: "Certificat d'incorporation",
    FormNewCompany_Step_1_Registration_4: 'max',
    FormNewCompany_Step_1_Registration_5: 'gauche',
    FormNewCompany_Step_1_Address_Title: 'Emplacement',
    FormNewCompany_Step_1_Different_Address_Title: 'Cette adresse est-elle la même que celle où sont stockés vos produits ?',
    FormNewCompany_Step_1_Different_Address_1: 'Non, ils sont situés à une adresse différente.',
    FormNewCompany_Step_1_Different_Address_2: 'Oui, ils sont situés à cette adresse.',
    FormNewCompany_Step_1_Warehouse_Name_1: "Nom de l'entrepôt",
    FormNewCompany_Step_1_Warehouse_Name_2: 'Entrepôt de ...',
    FormNewCompany_Step_2_Title: 'Détails et visuels',
    FormNewCompany_Step_2_Subtitle_1: 'Parfait !',
    FormNewCompany_Step_2_Subtitle_2: 'Vous présentez',
    FormNewCompany_Step_2_Subtitle_3: 'votre entreprise',
    FormNewCompany_Step_2_Subtitle_4: 'pour nous, maintenant vous pouvez décrire à votre public et à vos futurs clients ce que vous faites et à quel point',
    FormNewCompany_Step_2_Subtitle_5: 'est génial !',
    FormNewCompany_Step_2_Slogan_1: 'Slogan',
    FormNewCompany_Step_2_Slogan_2: 'Écrivez votre slogan',
    FormNewCompany_Step_2_Slogan_3: 'caractères',
    FormNewCompany_Step_2_Slogan_4: 'caractère',
    FormNewCompany_Step_2_Slogan_5: 'gauche',
    FormNewCompany_Step_2_Slogan_6: 'Vous devez supprimer',
    FormNewCompany_Step_2_Descriptions_Title: 'Descriptions (multi-langues)',
    FormNewCompany_Step_3_Title: 'Membres du personnel',
    FormNewCompany_Step_3_Subitle_1: 'Excellent progrès !',
    FormNewCompany_Step_3_Subitle_2: 'Vous pouvez également gérer et surveiller de nombreux aspects de',
    FormNewCompany_Step_3_Subitle_3: 'votre entreprise',
    FormNewCompany_Step_3_Subitle_4: 'et cela vous aidera à recruter les talents appropriés.',
    FormNewCompany_Step_3_Subitle_5: "Tout ce que vous avez à faire est d'envoyer une invitation à",
    FormNewCompany_Step_3_Subitle_6: "membres du personnel de l'entreprise.",
    FormNewCompany_Step_3_Subitle_7: "Vous pouvez envoyer plus d'invitations ultérieurement.",
    FormNewCompany_Step_3_Staffs_1: 'Envoyer des invitations à',
    FormNewCompany_Step_3_Staffs_2: 'Écrire un e-mail et appuyer sur ENTRÉE',
    FormNewCompany_Done_Title: 'Félicitations !',
    FormNewCompany_Done_Subtitle: 'Vous pouvez maintenant visiter la page de votre nouvelle entreprise',
    FormNewCompany_Done_1: 'Traitement de vos informations',
    FormNewCompany_Done_2: 'veuillez patienter...',
    FormNewCompany_Button_Create_Company: "Voir l'entreprise",
    FormNewCompany_Telephone_1: 'Numéro de téléphone',
    // COMPANY COMPONENT
    Company_Articles_Title: 'Articles',
    Company_Services_Title: 'Services',
    Company_Rentals_Title: 'Locations',
    Company_Tenders_Title: "Appels d'offres",
    Company_Fail_Loading_1: 'Nous sommes désolés, mais il semble que',
    Company_Fail_Loading_2: "n'est pas enregistré en tant qu'entreprise",
    Company_Loading_1: 'Chargement',
    Company_Loading_2: 'veuillez patienter...',
    Company_Owned_By: 'appartenant à',
    Company_Translations: 'Traductions',
    Company_Join_Fivezer: 'Rejoindre le réseau Fivezer',
    Company_Edit: "Modifier l'entreprise",
    Company_Manage_Members: 'Gérer les membres',
    Company_Supplychains: "Voir les chaînes d'approvisionnement",
    Company_Create_Wallet: 'Créer un portefeuille',
    Company_View_Wallets: 'Voir les portefeuilles',
    Company_Copied: 'Copié !',
    Company_Copy_Pluging: 'Copier le pluging',
    Company_Connect: 'Connecter avec',
    Company_Disconnect: 'Déconnecter de',
    Company_Follow: 'Suivre',
    Company_Unfollow: 'Ne plus suivre',
    Company_Add_Favorite: 'Ajouter aux favoris',
    Company_Your_Feedback: 'Votre avis sur',
    Company_Give_Feedback: 'Donner votre avis',
    Company_View_Members: 'Voir les membres',
    Company_Send_Message: 'Envoyer un message',
    Company_New_Deal: 'Nouvelle Transaction',
    Company_Share_1: 'Vous pouvez en savoir plus sur',
    Company_Share_2: 'sur ',
    Company_Registration: 'Immatriculation',
    Company_Not_Verified: 'Non vérifié',
    Company_Verified: 'Vérifié',
    Company_No_Description: 'Pas de description',
    Company_Visit: 'Visiter le Magasin de',
    Company_Store: ' ',
    Company_Locations_Title: 'Adresses',
    Company_Partners_Title: 'Nos partenaires',
    Company_Feedbacks_Title: 'Avis clients',
    Company_No_Feedbacks: 'Aucun avis client pour le moment',
    Company_Receommended_Tools_Title: 'Nos recommendations',
    Company_Receommended_Tools_Content: 'Nous avons sélectionné pour vous des partenaires qui vous aideront à améliorer votre business',
    Company_Verify_Email_1: "Attention: l'e-mail",
    Company_Verify_Email_2: "n'a pas été vérifié par le propriétaire",
    Company_Followers: 'Abonnés',
    Company_Follower: 'Abonné',
    Company_Not_Followers: "Pas d'abonnés",
    Company_Awards: 'Badges',
    Company_Award: 'Badge',
    // COMPONENT COMPANYLISTITEMS
    CompanyListItems_Add_Articles: 'Ajouter des articles',
    CompanyListItems_Add_Service: 'Ajouter un service',
    CompanyListItems_Add_Tender: "Ajouter un appel d'offres",
    CompanyListItems_Add_Rental: 'Ajouter une location',
    CompanyListItems_No_Articles: 'Aucun article pour le moment',
    CompanyListItems_No_Services: 'Aucun service pour le moment',
    CompanyListItems_No_Tenders: "Aucun appel d'offres pour le moment",
    CompanyListItems_No_Rentals: 'Aucune location pour le moment',
    // HORIZONTALENTITYLISTING COMPONENT
    HorizontalEntityListing_Updated: 'mis à jour',
    HorizontalEntityListing_Status: 'Statut',
    HorizontalEntityListing_Candidates: 'Candidats',
    HorizontalEntityListing_See_Mission: 'Voir la mission',
    HorizontalEntityListing_Proposals: 'Propositions',
    HorizontalEntityListing_See: 'Voir',
    // COMPONENT LISTSERVICESBOOKINGS
    ListServicesBookings_Services: 'Services',
    ListServicesBookings_Service: 'Service',
    ListServicesBookings_Booked: 'réservé par vos clients',
    // LISTLATESTSELLS COMPONENT
    ListLatestSells_Sells: 'ventes',
    ListLatestSells_Sell: 'vente',
    ListLatestSells_Booked: 'de vos articles',
    ListLatestSells_Button_See_More: 'Voir toutes les ventes',
    // INPUTDESCRIPTION COMPONENT
    InputDescriptionsFeature_Descriptions_Label: 'Langues disponibles (africaines et internationales)',
    InputDescriptionsFeature_Descriptions_Placeholder: 'Sélectionnez une langue',
    InputDescriptionsFeature_Add_Language: 'Ajouter la langue',
    InputDescriptionsFeature_Selected_Description_Label_1: 'Descriptions actuelles',
    InputDescriptionsFeature_Selected_Description_Label_2: 'et',
    InputDescriptionsFeature_Selected_Description_Label_3: 'descriptions',
    InputDescriptionsFeature_Selected_Description_Label_4: 'description',
    InputDescriptionsFeature_Selected_Description_Label_5: 'avec un contenu vide',
    InputDescriptionsFeature_Selected_Description_Placeholder: 'Sélectionnez une description',
    InputDescriptionsFeature_Short_Descriptions_Placeholder: 'Ajouter un courte description...',
    InputDescriptionsFeature_Selected_Description_Caption: '(contenu vide)',
    InputDescriptionsFeature_Selected_Description_Content: 'Description en',
    InputDescriptionsFeature_Selected_Short_Description_Content: 'Courte description en',
    InputDescriptionsFeature_Selected_Description_Content_Warning: "N'oubliez pas de sauvegarder votre contenu après chaque mise à jour",
    InputDescriptionsFeature_African_Languages: 'LANGUES AFRICAINES',
    InputDescriptionsFeature_OTHER_LANGUAGES: 'LANGUES INTERNATIONALES',
    // UTILITIES
    Utilities_Any_Industry: 'Toutes industries',
    Utilities_Any_Country: 'Tous les pays',
    Utilities_Any_Type: 'Tous types',
    Utilities_Advertising: 'Publicité',
    Utilities_Agriculture: 'Agriculture',
    Utilities_Bank_Industry: 'Industrie bancaire',
    Utilities_Communications: 'Communications',
    Utilities_Construction: 'Construction',
    Utilities_Creative_Industries: 'Industries créatives',
    Utilities_Education: 'Éducation',
    Utilities_Electronics_Industry: 'Industrie électronique',
    Utilities_Energy_Industry: "Industrie de l'énergie",
    Utilities_Entertainment: 'Divertissement',
    Utilities_Farming: 'Agriculture',
    Utilities_Fashion: 'Mode',
    Utilities_Finance: 'Finance',
    Utilities_Food_Industry: 'Industrie alimentaire',
    Utilities_Football_Five_Industry: 'Industrie du football à cinq',
    Utilities_Green: 'Environnement',
    Utilities_Health: 'Santé',
    Utilities_Heavy: 'Industrie lourde',
    Utilities_Hospitality_Industry: "Industrie de l'hospitalité",
    Utilities_Human_Resources_Industry: 'Industrie des ressources humaines',
    Utilities_Information_Industry: 'Information',
    Utilities_Information_Technology: "Technologie de l'information",
    Utilities_Infrastructure: 'Infrastructure',
    Utilities_IT_Industry: 'Industrie informatique',
    Utilities_Logistics_Industry: 'Industrie de la logistique',
    Utilities_Light_Industry: 'Industrie légère',
    Utilities_Manufacturing: 'Fabrication',
    Utilities_Materials: 'Matériaux',
    Utilities_Music_Industry: 'Industrie musicale',
    Utilities_News_Medias: "Médias d'information",
    Utilities_Pharmaceutical_Industry: 'Industrie pharmaceutique',
    Utilities_Primary: 'Primaire',
    Utilities_Public_Industry: 'Industrie publique',
    Utilities_Publishing_Industry: 'Édition',
    Utilities_Retail: 'Commerce de détail',
    Utilities_Robotics: 'Robotique',
    Utilities_Secondary: 'Secondaire',
    Utilities_Security_Service: 'Service de sécurité',
    Utilities_Service: 'Service',
    Utilities_Space: 'Espace',
    Utilities_Sport: 'Sport',
    Utilities_Technology: 'Technologie',
    Utilities_Telecom: 'Télécommunications',
    Utilities_Tertiary_Sector: 'Secteur tertiaire',
    Utilities_Textiles_Industry: 'Industrie textile',
    Utilities_Tourism: 'Tourisme',
    Utilities_Transportation_Industry: 'Industrie du transport',
    Utilities_Travel: 'Voyage',
    Utilities_Female: 'Femme',
    Utilities_Male: 'Homme',
    Utilities_Other: 'Autre',
    Utilities_XS_Parcel: 'Colis XS',
    Utilities_S_Parcel: 'Colis S',
    Utilities_M_Parcel: 'Colis M',
    Utilities_L_Parcel: 'Colis L',
    Utilities_XL_Parcel: 'Colis XL',
    Utilities_XLL_Parcel: 'Colis XLL',
    Utilities_Passport: 'Passeport',
    Utilities_National_ID: "Carte d'identité nationale",
    Utilities_Driving_Licence: 'Permis de conduire',
    Utilities_Temporarily_ID: "Carte d'identité temporaire",
    Utilities_Permanent_Full_Time: 'Permanent (temps plein)',
    Utilities_Permanent_Part_Time: 'Permanent (temps partiel)',
    Utilities_Temporary_Full_Time: 'Temporaire (temps plein)',
    Utilities_Temporary_Part_Time: 'Temporaire (temps partiel)',
    Utilities_Internship_Full_Time: 'Stage (temps plein)',
    Utilities_Internship_Part_Time: 'Stage (temps partiel)',
    Utilities_Create_As_Admin: "Créer en tant qu'administrateur",
    Utilities_View_As_Admin: "Afficher en tant qu'administrateur",
    Utilities_Update_As_Admin: "Mettre à jour en tant qu'administrateur",
    Utilities_Delete_As_Admin: "Supprimer en tant qu'administrateur",
    Utilities_Create_Positions: 'Créer des postes',
    Utilities_View_Own_Positions: 'Voir ses propres postes',
    Utilities_View_Any_Positions: 'Voir tous les postes',
    Utilities_Update_Own_Positions: 'Mettre à jour ses propres postes',
    Utilities_Update_Any_Positions: 'Mettre à jour tous les postes',
    Utilities_Delete_Own_Positions: 'Supprimer ses propres postes',
    Utilities_Delete_Any_Positions: 'Supprimer tous les postes',
    Utilities_Create_Products: 'Créer des produits',
    Utilities_View_Own_Products: 'Voir ses propres produits',
    Utilities_View_Any_Products: 'Voir tous les produits',
    Utilities_Update_Own_Products: 'Mettre à jour ses propres produits',
    Utilities_Update_Any_Products: 'Mettre à jour tous les produits',
    Utilities_Delete_Own_Products: 'Supprimer ses propres produits',
    Utilities_Delete_Any_Products: 'Supprimer tous les produits',
    Utilities_Create_Services: 'Créer des services',
    Utilities_View_Own_Services: 'Voir ses propres services',
    Utilities_View_Any_Services: 'Voir tous les services',
    Utilities_Update_Own_Services: 'Mettre à jour ses propres services',
    Utilities_Update_Any_Services: 'Mettre à jour tous les services',
    Utilities_Delete_Own_Services: 'Supprimer ses propres services',
    Utilities_Delete_Any_Services: 'Supprimer tous les services',
    Utilities_Create_Tenders: "Créer des appels d'offres",
    Utilities_View_Own_Tenders: "Voir ses propres appels d'offres",
    Utilities_View_Any_Tenders: "Voir tous les appels d'offres",
    Utilities_Update_Own_Tenders: "Mettre à jour ses propres appels d'offres",
    Utilities_Update_Any_Tenders: "Mettre à jour tous les appels d'offres",
    Utilities_Delete_Own_Tenders: "Supprimer ses propres appels d'offres",
    Utilities_Delete_Any_Tenders: "Supprimer tous les appels d'offres",
    Utilities_Create_Job_Proposals: "Créer des propositions d'emploi",
    Utilities_View_Own_Job_Proposals: "Voir ses propres propositions d'emploi",
    Utilities_View_Any_Job_Proposals: "Voir toutes les propositions d'emploi",
    Utilities_Update_Own_Job_Proposals: "Mettre à jour ses propres propositions d'emploi",
    Utilities_Update_Any_Job_Proposals: "Mettre à jour toutes les propositions d'emploi",
    Utilities_Delete_Own_Job_Proposals: "Supprimer ses propres propositions d'emploi",
    Utilities_Delete_Any_Job_Proposals: "Supprimer toutes les propositions d'emploi",
    Utilities_Create_Alerts: 'Créer des alertes',
    Utilities_View_Own_Alerts: 'Voir ses propres alertes',
    Utilities_View_Any_Alerts: 'Voir toutes les alertes',
    Utilities_Update_Own_Alerts: 'Mettre à jour ses propres alertes',
    Utilities_Update_Any_Alerts: 'Mettre à jour toutes les alertes',
    Utilities_Delete_Own_Alerts: 'Supprimer ses propres alertes',
    Utilities_Delete_Any_Alerts: 'Supprimer toutes les alertes',
    Utilities_Create_Quotations: 'Créer des devis',
    Utilities_View_Own_Quotations: 'Voir ses propres devis',
    Utilities_View_Any_Quotations: 'Voir tous les devis',
    Utilities_Update_Own_Quotations: 'Mettre à jour ses propres devis',
    Utilities_Update_Any_Quotations: 'Mettre à jour tous les devis',
    Utilities_Delete_Own_Quotations: 'Supprimer ses propres devis',
    Utilities_Delete_Any_Quotations: 'Supprimer tous les devis',
    Utilities_Aerospace_Company: 'Entreprise aérospatiale',
    Utilities_Agriculture_Company: 'Entreprise agricole',
    Utilities_Auditor_Partnership: "Partenariat d'auditeur",
    Utilities_Automotive_Company: 'Entreprise automobile',
    Utilities_Biotechnology_Company: 'Entreprise de biotechnologie',
    Utilities_Cargo_Freight_Company: 'Entreprise de transport de marchandises',
    Utilities_Cause: 'Cause',
    Utilities_Chemical_Company: 'Entreprise chimique',
    Utilities_College_University: 'Collège et université',
    Utilities_Community_Organization: 'Organisation communautaire',
    Utilities_Community_Services: 'Services communautaires',
    Utilities_Computer_Company: 'Entreprise informatique',
    Utilities_Consulting_Agency: 'Agence de conseil',
    Utilities_Elementary_School: 'École primaire',
    Utilities_Energy_Company: 'Entreprise énergétique',
    Utilities_Finance_Company: 'Entreprise financière',
    Utilities_Fintech_Partnership: 'Fintech Partnership',
    Utilities_Fivezer_Partnership: 'Fivezer Partnership',
    Utilities_Food_Beverage_Company: 'Entreprise alimentaire et de boissons',
    Utilities_Football_League: 'Ligue de football',
    Utilities_Government_Organization: 'Organisation gouvernementale',
    Utilities_Health_Beauty_Company: 'Entreprise de santé/beauté',
    Utilities_Health_Partnership: 'Health Partnership',
    Utilities_High_School: 'Lycée',
    Utilities_Industrial_Company: 'Entreprise industrielle',
    Utilities_Insurance_Company: "Compagnie d'assurance",
    Utilities_Internet_Company: 'Entreprise internet',
    Utilities_Labor_Union: 'Syndicat',
    Utilities_Legal_Company: "Cabinet d'avocats",
    Utilities_Logistics: 'Logistique',
    Utilities_Logistics_Partnership: 'Logistics Partnership',
    Utilities_Insurances_Partnership: 'Insurances Partnership',
    Utilities_Media_News_Company: 'Entreprise de médias/nouvelles',
    Utilities_Medical_Company: 'Entreprise médicale',
    Utilities_Middle_School: 'Collège',
    Utilities_Mining_Company: 'Entreprise minière',
    Utilities_Non_Governmental_Organization: 'Organisation non gouvernementale',
    Utilities_Non_Profit_Organization: 'Organisation à but non lucratif',
    Utilities_Organization: 'Organisation',
    Utilities_Political_Organization: 'Organisation politique',
    Utilities_Political_Party: 'Parti politique',
    Utilities_Preschool: 'École maternelle',
    Utilities_Religious_Organization: 'Organisation religieuse',
    Utilities_Retail_Company: 'Entreprise de vente au détail',
    Utilities_Retail_Investor_Partnership: 'Retail Investor Partnership',
    Utilities_Risk_Management_Partnership: 'Risks Management Partnership',
    Utilities_School: 'École',
    Utilities_Science: 'Science',
    Utilities_Security_Partnership: 'Security Partnership',
    Utilities_Statistics: 'Statistiques',
    Utilities_Talent_Resources_Partnership: 'Talent Resources Partnership',
    Utilities_Commodity_Compliance_Partnership: 'Commodity Compliance Partnership',
    Utilities_Technology_Engineering: 'Technologie et ingénierie',
    Utilities_Telecommunication_Company: 'Entreprise de télécommunication',
    Utilities_Tobacco_Company: 'Entreprise du tabac',
    Utilities_Travel_Company: 'Entreprise de voyage',

    Utilities_Skin_cares: 'Soins de la peau',
    Utilities_Agriculture_Food: 'Agriculture et alimentation',
    Utilities_Apparel: 'Vêtements',
    Utilities_Appliances: 'Appareils électroménagers',
    Utilities_Apps_Games: 'Applications et jeux',
    Utilities_Arts_Crafts_Sewing: 'Arts, artisanat et couture',
    Utilities_Automotive_Parts_Accessories: 'Pièces et accessoires automobiles',
    Utilities_Baby: 'Bébé',
    Utilities_Beauty_Personal_Care: 'Beauté et soins personnels',
    Utilities_Books: 'Livres',
    Utilities_CDs_Vinyl: 'CD et vinyle',
    Utilities_Cell_Phones_Accessories: 'Téléphones portables et accessoires',
    Utilities_Clothing_Shoes_and_Jewelry: 'Vêtements, chaussures et bijoux',
    Utilities_Collectibles_Fine_Art: 'Objets de collection et beaux-arts',
    Utilities_Computers: 'Informatique',
    Utilities_Electronics: 'Électronique',
    Utilities_Fashion_Accessories: 'Accessoires de mode',
    Utilities_Food_Beverage: 'Alimentation et boissons',
    Utilities_Furniture: 'Meubles',
    Utilities_Gifts_Crafts: 'Cadeaux et artisanat',
    Utilities_Garden_Outdoor: 'Jardin et plein air',
    Utilities_Health_Household_Baby_Care: 'Santé, ménage et soins du bébé',
    Utilities_Home_Appliances: 'Électroménagers',
    Utilities_Home_Kitchen: 'Maison et cuisine',
    Utilities_Home_Garden: 'Maison et jardin',
    Utilities_Industrial_Scientific: 'Industriel et scientifique',
    Utilities_Lights_Lighting: 'Éclairage',
    Utilities_Luggage_Travel_Gear: 'Bagages et équipement de voyage',
    Utilities_Machinery: 'Machines',
    Utilities_Movies_TV: 'Films et télévision',
    Utilities_Musical_Instruments: 'Instruments de musique',
    Utilities_Office_Products: 'Produits de bureau',
    Utilities_Packaging_Printing: 'Emballage et impression',
    Utilities_Pet_Supplies: 'Fournitures pour animaux de compagnie',
    Utilities_Shoes_Accessories: 'Chaussures et accessoires',
    Utilities_Sports_Entertainment: 'Sports et divertissements',
    Utilities_Sports_Outdoors: 'Sports et plein air',
    Utilities_Textiles_Leather_Products: 'Produits textiles et en cuir',
    Utilities_Timepieces_Jewelry_Eyewear: 'Montres, bijoux, lunettes',
    Utilities_Tools_Home_Improvement: "Outils et amélioration de l'habitat",
    Utilities_Toys_Hobbies: 'Jouets et loisirs',
    Utilities_Toys_Games: 'Jouets et jeux',
    Utilities_Video_Games: 'Jeux vidéo',

    Utilities_Company: 'Entreprise',
    Utilities_Services: 'Services',
    Utilities_Product: 'Produit',

    Utilities_Accounting: 'Comptabilité',
    Utilities_Administrative: 'Administratif',
    Utilities_Assistance_Advertising: 'Assistance Publicitaire',
    Utilities_Android_Development: 'Développement Android',
    Utilities_Animation_Architecture: 'Animation Architecture',
    Utilities_Audiology: 'Audiologie',
    Utilities_Audio_Engineering: 'Ingénierie Audio',
    Utilities_Auto_Insurance: 'Assurance Auto',
    Utilities_Bankruptcy_Law: 'Droit de la Faillite',
    Utilities_Bartending: 'Barman',
    Utilities_Blogging: 'Blogging',
    Utilities_Bookkeeping: 'Tenue de Livres',
    Utilities_Budgeting: 'Élaboration de Budget',
    Utilities_Business_Analytics: "Analyse d'Entreprise",
    Utilities_Business_Law: 'Droit des Affaires',
    Utilities_Catering_Service: 'Service Traiteur',
    Utilities_Coaching_Mentoring: 'Coaching & Mentorat',
    Utilities_Commercial_Insurance: 'Assurance Commerciale',
    Utilities_Commercial_Real_Estate: 'Immobilier Commercial',
    Utilities_Computer_Networking: 'Réseautage Informatique',
    Utilities_Computer_Repair: 'Réparation Informatique',
    Utilities_Consulting: 'Consultation',
    Utilities_Content_Strategy: 'Stratégie de Contenu',
    Utilities_Copywriting: 'Rédaction Publicitaire',
    Utilities_Corporate_Events: "Événements d'Entreprise",
    Utilities_Corporate_Law: 'Droit des Sociétés',
    Utilities_Customer_Service: 'Service Client',
    Utilities_Customer_Support: 'Support Client',
    Utilities_Cybersecurity: 'Cybersécurité',
    Utilities_Digital_Marketing: 'Marketing Numérique',
    Utilities_DJing: 'DJing',
    Utilities_DUI_Law: "Droit de l'Alcool au Volant",
    Utilities_Editing: 'Édition',
    Utilities_Email_Marketing: 'Marketing par E-mail',
    Utilities_Event_Photography: "Photographie d'Événements",
    Utilities_Event_Planning: "Planification d'Événements",
    Utilities_Executive_Coaching: 'Coaching Exécutif',
    Utilities_Family_Law: 'Droit de la Famille',
    Utilities_File_Management: 'Gestion de Fichiers',
    Utilities_Filing: 'Classement',
    Utilities_Financial_Accounting: 'Comptabilité Financière',
    Utilities_Financial_Advisory: 'Conseil Financier',
    Utilities_Financial_Analysis: 'Analyse Financière',
    Utilities_Financial_Planning: 'Planification Financière',
    Utilities_Financial_Reporting: 'Rapports Financiers',
    Utilities_Ghostwriting: 'Ghostwriting',
    Utilities_Grant_Writing: 'Rédaction de Subventions',
    Utilities_Graphic_Design: 'Graphisme',
    Utilities_Growth_Marketing: 'Marketing de Croissance',
    Utilities_Headshot_Photography: 'Photographie de Portrait',
    Utilities_Health_Insurance: 'Assurance Santé',
    Utilities_Healthcare_Consulting: 'Conseil en Soins de Santé',
    Utilities_Home_Networking: 'Réseau Domestique',
    Utilities_Home_Owners_Insurance: 'Assurance Habitation',
    Utilities_HR_Consulting: 'Conseil en Ressources Humaines',
    Utilities_Human_Resources: 'Ressources Humaines',
    Utilities_Illustration: 'Illustration',
    Utilities_Immigration_Law: "Droit de l'Immigration",
    Utilities_Information_Management: "Gestion de l'Information",
    Utilities_Information_Security: "Sécurité de l'Information",
    Utilities_Insurance: 'Assurance',
    Utilities_Interaction_Design: "Design d'Interaction",
    Utilities_Interior_Design: "Design d'Intérieur",
    Utilities_iOS_Development: 'Développement iOS',
    Utilities_Labor_and_Employment_Law: "Droit du Travail et de l'Emploi",
    Utilities_Lead_Generation: 'Génération de Leads',
    Utilities_Leadership_Development: 'Développement du Leadership',
    Utilities_Life_Coaching: 'Coaching de Vie',
    Utilities_Life_Insurance: 'Assurance Vie',
    Utilities_Loans: 'Prêts',
    Utilities_Logo_Design: 'Design de Logo',
    Utilities_Management_Consulting: 'Conseil en Gestion',
    Utilities_Manufacturing_Service: 'Services de fabrication',
    Utilities_Market_Research: 'Études de Marché',
    Utilities_Market_Strategy: 'Stratégie de Marché',
    Utilities_Marketing: 'Marketing',
    Utilities_Mobile_Marketing: 'Marketing Mobile',
    Utilities_Mortgage_Lending: 'Prêts Hypothécaires',
    Utilities_Nature_Photography: 'Photographie de Nature',
    Utilities_Negotiation: 'Négociation',
    Utilities_Network_Support: 'Support Réseau',
    Utilities_Nonprofit_Consulting: 'Conseil à But Non Lucratif',
    Utilities_Notary_Online_Research: 'Recherche en Ligne Notariale',
    Utilities_Outsourcing: 'Externalisation',
    Utilities_Packaging_Design: "Design d'Emballage",
    Utilities_Personal_Injury_Law: 'Droit des Blessures Personnelles',
    Utilities_Portrait_Photography: 'Photographie de Portrait',
    Utilities_Pricing_Strategy: 'Stratégie de Tarification',
    Utilities_Print_Design: "Design d'Impression",
    Utilities_Product_Marketing: 'Marketing de Produits',
    Utilities_Program_Management: 'Gestion de Programmes',
    Utilities_Project_Management: 'Gestion de Projets',
    Utilities_Property_Management: 'Gestion Immobilière',
    Utilities_Public_Relations: 'Relations Publiques',
    Utilities_Public_Speaking: 'Prise de Parole en Public',
    Utilities_Real_Estate: 'Immobilier',
    Utilities_Real_Estate_Appraisal: 'Évaluation Immobilière',
    Utilities_Relocation: 'Déménagement',
    Utilities_Research: 'Recherche',
    Utilities_Resume_Writing: 'Rédaction de CV',
    Utilities_Retirement_Planning: 'Planification de la Retraite',
    Utilities_Search_Engine_Marketing: 'Marketing des Moteurs de Recherche',
    Utilities_Search_Engine_Optimization: 'Optimisation des Moteurs de Recherche',
    Utilities_Social_Media_Marketing: 'Marketing sur les Réseaux Sociaux',
    Utilities_Software_Testing: 'Test Logiciel',
    Utilities_Strategic_Planning: 'Planification Stratégique',
    Utilities_Tax_Law: 'Droit Fiscal',
    Utilities_Tax_Preparation: 'Préparation Fiscale',
    Utilities_Team_Building: "Construction d'Équipe",
    Utilities_Technical: 'Technique',
    Utilities_Support_Technical_Writing: 'Assistance Rédaction Technique',
    Utilities_Telecommunications: 'Télécommunications',
    Utilities_Trade_Shows: 'Salons Professionnels',
    Utilities_Training: 'Formation',
    Utilities_Translation: 'Traduction',
    Utilities_Typing: 'Frappe',
    Utilities_User_Experience_Design: "Design de l'Expérience Utilisateur",
    Utilities_User_Experience_Writing: "Rédaction de l'Expérience Utilisateur",
    Utilities_UX_Research: 'Recherche UX',
    Utilities_Video_Animation: 'Animation Vidéo',
    Utilities_Video_Editing: 'Montage Vidéo',
    Utilities_Video_Production: 'Production Vidéo',
    Utilities_Videography: 'Vidéographie',
    Utilities_Virtual_Assistance: 'Assistance Virtuelle',
    Utilities_Visual_Design: 'Design Visuel',
    Utilities_Wealth_Management: 'Gestion de Patrimoine',
    Utilities_Web_Design: 'Conception Web',
    Utilities_Web_Development: 'Développement Web',
    Utilities_Wedding_Photography: 'Photographie de Mariage',
    Utilities_Wedding_Planning: 'Planification de Mariage',
    Utilities_WordPress_Design: 'Design WordPress',
    Utilities_Writing: 'Rédaction',

    Utilities_Affirmation: 'Affirmation',
    Utilities_Affirmation_1: 'Je suis satisfait de mon achat !',
    Utilities_Affirmation_2: 'Expérience globale géniale.',
    Utilities_Affirmation_3: 'Merci pour votre excellent service !',

    Utilities_Anger: 'Colère',
    Utilities_Anger_1: 'Je suis frustré par le service.',
    Utilities_Anger_2: 'Déçu de mon expérience.',
    Utilities_Anger_3: 'Mécontent de la qualité du produit.',

    Utilities_Anxiety: 'Anxiété',
    Utilities_Anxiety_1: "Sentiment d'anxiété par rapport à l'achat.",
    Utilities_Anxiety_2: 'Inquiétude concernant le service.',
    Utilities_Anxiety_3: "Anxiété en raison d'une mauvaise expérience.",

    Utilities_Blushing: 'Rougeur',
    Utilities_Blushing_1: "Se sentir gêné par l'achat.",
    Utilities_Blushing_2: 'Rougeur due à une situation gênante.',
    Utilities_Blushing_3: 'Gêné par le service reçu.',

    Utilities_Contempt: 'Mépris',
    Utilities_Contempt_1: "Sentir du mépris pour l'achat.",
    Utilities_Contempt_2: 'Méprisant envers le service reçu.',
    Utilities_Contempt_3: "Mécontent de l'expérience globale.",

    Utilities_Despair: 'Désespoir',
    Utilities_Despair_1: "En désespoir en raison de l'achat.",
    Utilities_Despair_2: "Sentiment d'impuissance par rapport au service.",
    Utilities_Despair_3: "Expérience désespérée dans l'ensemble.",

    Utilities_Determination: 'Détermination',
    Utilities_Determination_1: 'Déterminé à donner un avis.',
    Utilities_Determination_2: "Exprimer de la détermination dans l'avis.",
    Utilities_Determination_3: "Firme dans le partage de ses impressions sur l'achat.",

    Utilities_Devotion: 'Dévouement',
    Utilities_Devotion_1: 'Dévoué à fournir un retour.',
    Utilities_Devotion_2: "Exprimer du dévouement dans l'avis.",
    Utilities_Devotion_3: "Réflexions dédiées sur l'achat.",

    Utilities_Disdain: 'Dédain',
    Utilities_Disdain_1: "Exprimer du dédain pour l'achat.",
    Utilities_Disdain_2: 'Pensées dédaigneuses sur le service.',
    Utilities_Disdain_3: "Mécontent de l'expérience globale.",

    Utilities_Disgust: 'Dégoût',
    Utilities_Disgust_1: "Exprimer du dégoût pour l'achat.",
    Utilities_Disgust_2: 'Pensées dégoûtantes sur le service.',
    Utilities_Disgust_3: "Mécontent de l'expérience globale.",

    Utilities_Fear: 'Peur',
    Utilities_Fear_1: "Sentiment de peur par rapport à l'achat.",
    Utilities_Fear_2: 'Inquiétude concernant le service.',
    Utilities_Fear_3: "Peur en raison d'une mauvaise expérience.",

    Utilities_Grief: 'Chagrin',
    Utilities_Grief_1: "Chagrin dû à l'achat.",
    Utilities_Grief_2: 'Sentiment de tristesse par rapport au service.',
    Utilities_Grief_3: "Expérience chagrine dans l'ensemble.",

    Utilities_Guilt: 'Culpabilité',
    Utilities_Guilt_1: "Sentiment de culpabilité par rapport à l'achat.",
    Utilities_Guilt_2: 'Culpabilité liée au service.',
    Utilities_Guilt_3: "Regret et culpabilité dans l'expérience globale.",

    Utilities_Hatred: 'Haine',
    Utilities_Hatred_1: "Exprimer de la haine pour l'achat.",
    Utilities_Hatred_2: 'Sentiment de haine envers le service.',
    Utilities_Hatred_3: "Mécontent de l'expérience globale.",

    Utilities_Helplessness: 'Impuissance',
    Utilities_Helplessness_1: "Sentiment d'impuissance par rapport à l'achat.",
    Utilities_Helplessness_2: 'Se sentir impuissant face au service.',
    Utilities_Helplessness_3: 'Impuissance due à une mauvaise expérience.',

    Utilities_HighSpirits: 'Hauts esprits',
    Utilities_HighSpirits_1: "Sentiment positif par rapport à l'achat.",
    Utilities_HighSpirits_2: 'Excellente expérience globale.',
    Utilities_HighSpirits_3: 'Merci pour votre excellent service !',

    Utilities_IllTemper: 'Mauvaise humeur',
    Utilities_IllTemper_1: "Sentiment de mauvaise humeur par rapport à l'achat.",
    Utilities_IllTemper_2: 'Expérience globale décevante.',
    Utilities_IllTemper_3: 'Mécontent de la qualité du produit.',

    Utilities_Joy: 'Joie',
    Utilities_Joy_1: "Sentiment de joie par rapport à l'achat.",
    Utilities_Joy_2: 'Expérience globale géniale.',
    Utilities_Joy_3: 'Merci pour votre excellent service !',

    Utilities_Love: 'Amour',
    Utilities_Love_1: "Sentiment d'amour par rapport à l'achat.",
    Utilities_Love_2: 'Expérience globale géniale.',
    Utilities_Love_3: 'Merci pour votre excellent service !',

    Utilities_LowSpirits: 'Basse humeur',
    Utilities_LowSpirits_1: "Sentiment négatif par rapport à l'achat.",
    Utilities_LowSpirits_2: 'Expérience globale décevante.',
    Utilities_LowSpirits_3: 'Mécontent de la qualité du produit.',

    Utilities_Mediation: 'Médiation',
    Utilities_Mediation_1: "Méditer sur l'achat.",
    Utilities_Mediation_2: 'Pensées méditatives sur le service.',
    Utilities_Mediation_3: "Réflexion approfondie sur l'expérience globale.",

    Utilities_Modesty: 'Modestie',
    Utilities_Modesty_1: "Exprimer de la modestie dans l'achat.",
    Utilities_Modesty_2: 'Pensées modestes sur le service.',
    Utilities_Modesty_3: "Modestie dans l'expérience globale.",

    Utilities_Negation: 'Négation',
    Utilities_Negation_1: "Exprimer la négation dans l'achat.",
    Utilities_Negation_2: 'Pensées négatives sur le service.',
    Utilities_Negation_3: "Négation dans l'expérience globale.",

    Utilities_Neutral: 'Neutre',
    Utilities_Neutral_1: "C'est correct par rapport à l'achat.",
    Utilities_Neutral_2: 'Pensées neutres sur le service.',
    Utilities_Neutral_3: "C'est correct dans l'expérience globale.",

    Utilities_Patience: 'Patience',
    Utilities_Patience_1: "Patience dans l'achat.",
    Utilities_Patience_2: 'Pensées patientes sur le service.',
    Utilities_Patience_3: "Patience dans l'expérience globale.",

    Utilities_Pride: 'Fierté',
    Utilities_Pride_1: "Exprimer de la fierté dans l'achat.",
    Utilities_Pride_2: 'Pensées fières sur le service.',
    Utilities_Pride_3: "Fierté dans l'expérience globale.",

    Utilities_Reflection: 'Réflexion',
    Utilities_Reflection_1: "Réflexion sur l'achat.",
    Utilities_Reflection_2: 'Pensées réfléchies sur le service.',
    Utilities_Reflection_3: "Réflexion approfondie sur l'expérience globale.",

    Utilities_SelfAttention: 'Auto-attention',
    Utilities_SelfAttention_1: "Payer attention à soi dans l'achat.",
    Utilities_SelfAttention_2: "Pensées d'auto-attention sur le service.",
    Utilities_SelfAttention_3: "Auto-attention dans l'expérience globale.",

    Utilities_Shyness: 'Timidité',
    Utilities_Shyness_1: "Sentiment de timidité par rapport à l'achat.",
    Utilities_Shyness_2: 'Pensées timides sur le service.',
    Utilities_Shyness_3: "Timidité dans l'expérience globale.",

    Utilities_Suffering: 'Souffrance',
    Utilities_Suffering_1: "Sentiment de souffrance par rapport à l'achat.",
    Utilities_Suffering_2: 'Pensées de souffrance sur le service.',
    Utilities_Suffering_3: "Souffrance dans l'expérience globale.",

    Utilities_Sulkiness: 'Mauvaise humeur',
    Utilities_Sulkiness_1: "Sentiment de mauvaise humeur par rapport à l'achat.",
    Utilities_Sulkiness_2: 'Expérience globale décevante.',
    Utilities_Sulkiness_3: 'Mécontent de la qualité du produit.',

    Utilities_Surprise: 'Surprise',
    Utilities_Surprise_1: "Sentiment de surprise par rapport à l'achat.",
    Utilities_Surprise_2: 'Expérience globale surprenante.',
    Utilities_Surprise_3: "Agréable surprise dans l'expérience globale.",

    Utilities_TenderFeelings: 'Sentiments tendres',
    Utilities_TenderFeelings_1: "Exprimer des sentiments tendres dans l'achat.",
    Utilities_TenderFeelings_2: 'Pensées tendres sur le service.',
    Utilities_TenderFeelings_3: "Sentiments tendres dans l'expérience globale.",

    Utilities_Weeping: 'Larmes',
    Utilities_Weeping_1: "Larmes liées à l'achat.",
    Utilities_Weeping_2: 'Sentiment de tristesse par rapport au service.',
    Utilities_Weeping_3: "Expérience larmoyante dans l'ensemble.",

    Utilities_Alert_Awareness: 'Conscience',
    Utilities_Alert_Warning: 'Avertissement',
    Utilities_Alert_Danger: 'Danger',

    Utilities_Chemical_Industry: 'Industrie chimique',
    Utilities_Metals_Industry: 'Industrie métallurgique',
    Utilities_Textile_Industry: 'Industrie textile',
    Utilities_Plastics_Industry: 'Industrie des plastiques',
    Utilities_Paper_Pulp_Industry: 'Industrie papetière et de la pâte à papier',
    Utilities_Ceramics_Industry: 'Industrie céramique',
    Utilities_Glass_Industry: 'Industrie du verre',
    Utilities_Rubber_Industry: 'Industrie du caoutchouc',
    Utilities_Wood_Timber_Industry: 'Industrie du bois',
    Utilities_Aerospace_Industry: 'Industrie aérospatiale',
    Utilities_Defense_Industry: 'Industrie de la défense',
    Utilities_Mining_Industry: 'Industrie minière',
    Utilities_Water_Treatment_Industry: "Industrie du traitement de l'eau",
    Utilities_Renewable_Energy_Industry: 'Industrie des énergies renouvelables',
    Utilities_Chemical_Process_Equipment: 'Équipement de procédés chimiques',
    Utilities_Packaging_Industry: "Industrie de l'emballage",
    Utilities_Pharmaceutical_Manufacturing: 'Fabrication pharmaceutique',
    Utilities_HVAC_Industry: 'Industrie du chauffage, de la ventilation et de la climatisation (HVAC)',
    Utilities_Instrumentation_Control_Systems: "Systèmes d'instrumentation et de contrôle",
    Utilities_Robotics_Automation_Industry: "Industrie de la robotique et de l'automatisation",
    Utilities_Waste_Management: 'Gestion des déchets',

    Utilities_Bearings: 'Roulements',
    Utilities_Conveyor_Systems: 'Systèmes de Convoyeurs',
    Utilities_Electrical_Components: 'Composants Électriques',
    Utilities_Fasteners: 'Fixations',
    Utilities_Filtration_Systems: 'Systèmes de Filtration',
    Utilities_Hydraulic_Equipment: 'Équipements Hydrauliques',
    Utilities_Industrial_Coatings: 'Revêtements Industriels',
    Utilities_Instrumentation: 'Instrumentation',
    Utilities_Lubricants: 'Lubrifiants',
    Utilities_Machining_Tools: "Outils d'Usinage",
    Utilities_Motors: 'Moteurs',
    Utilities_Pneumatic_Equipment: 'Équipements Pneumatiques',
    Utilities_Power_Tools: 'Outils Électriques',
    Utilities_Pumps: 'Pompes',
    Utilities_Raw_Materials: 'Matières Premières',
    Utilities_Safety_Equipment: 'Équipements de Sécurité',
    Utilities_Sensors: 'Capteurs',
    Utilities_Valves: 'Vannes',
    Utilities_Welding_Equipment: 'Équipements de Soudage',

    Utilities_Publicity: 'Publicité',
    Utilities_Recruiting: 'Recrutement',
    Utilities_Relationship_Coaching: 'Coaching relationnel',
    Utilities_Residential_Real_Estate: 'Immobilier résidentiel',
    Utilities_Sales: 'Ventes',
    Utilities_Sales_Training: 'Formation en vente',
    Utilities_Scriptwriting: 'Écriture de scénarios',
    Utilities_SEO: 'SEO',
    Utilities_Social_Media_Strategy: 'Stratégie des réseaux sociaux',
    Utilities_Software_Development: 'Développement de logiciels',
    Utilities_Technical_Support: 'Support technique',
    Utilities_Technical_Writing: 'Rédaction technique',
    Utilities_Community_Outreach: 'Sensibilisation communautaire',
    Utilities_Environmental_Consulting: 'Consultation environnementale',
    Utilities_Legal_Consulting: 'Consultation juridique',
    Utilities_Risk_Management: 'Gestion des risques',
    Utilities_Sustainability_Consulting: 'Consultation en durabilité',
    Utilities_Industrial_Automation: 'Automatisation industrielle',
    Utilities_Manufacturing_Consulting: 'Consultation en fabrication',
    Utilities_Process_Engineering: 'Génie des procédés',
    Utilities_Quality_Control: 'Contrôle de qualité',
    Utilities_Safety_Engineering: 'Ingénierie de la sécurité',
    Utilities_Supply_Chain_Management: "Gestion de la chaîne d'approvisionnement",
    Utilities_Industrial_Equipment_Maintenance: 'Maintenance des équipements industriels',
    Utilities_Facility_Management: 'Gestion des installations',
    Utilities_Energy_Management: "Gestion de l'énergie",
    Utilities_Environmental_Compliance: 'Conformité environnementale',
    Utilities_Industrial_Design: 'Conception industrielle',
    Utilities_Logistics_Management: 'Gestion de la logistique',
    Utilities_Materials_Management: 'Gestion des matériaux',
    Utilities_Plant_Engineering: "Génie d'usine",
    Utilities_Project_Engineering: 'Génie de projet',
    Utilities_Industrial_Training: 'Formation industrielle',
    Utilities_Maintenance_Planning: 'Planification de la maintenance',
    Utilities_Adhesives_Sealants: 'Colles et jointoyeurs',
    Utilities_Software: 'Logiciels',
    Utilities_Home_Decor: "Décoration d'intérieur",
    Utilities_Stationery: 'Papeterie',
    Utilities_Craft_Beverages: 'Boissons artisanales',
    Utilities_Outdoor_Recreation: 'Loisirs extérieurs',
    Utilities_Watches: 'Montres',
    Utilities_Baby_Clothing: 'Vêtements pour bébés',
    Utilities_Personal_Care_Appliances: 'Appareils de soins personnels',

    // SETTINGS COMPONENT
    Settings_Title: 'Configurations',
    Settings_Account: 'Compte',
    Settings_Wallets: 'Portefeuilles',
    Settings_Languages: 'Langues',
    Settings_Helpdesk: "Service d'assistance",
    Settings_Legal_Mentions: 'Mentions légales',
    Settings_Logout: 'Déconnexion',
    Settings_Logging_Out: 'Déconnexion...',
    // NEWDEAL COMPONENT
    NewDeal_Processing: 'Accord en cours de traitement, veuillez patienter...',
    // NEWCOMMODITYCOMPLIANCE MODAL
    NewRequestCommodityComplianceModal_Title: 'Nouvelle demande de conformité pour',
    NewRequestCommodityComplianceModal_Button_New_Case: 'Nouvelle demande de conformité',
    NewRequestCommodityComplianceModal_Descriptions_Title: 'Descriptions (multi-langues)',
    NewRequestCommodityComplianceModal_Button_Create: 'Soumettre la demande',
    // VIEWCOMMODITYCOMPLIANCEREQUEST MODAL
    ViewCommodityComplianceRequestsModal_Button_View: 'Voir les demandes',
    // EditCommodityComplianceProposalModal
    EditCommodityComplianceProposalModal_Title: 'Mettre à jour la proposition',
    EditCommodityComplianceProposalModal_Descriptions_Title: 'Descriptions (multi-langues)',
    EditCommodityComplianceProposalModal_Tasks_Title: 'Liste des tâches',
    EditCommodityComplianceProposalModal_Duration: 'Durée',
    EditCommodityComplianceProposalModal_Duration_Unit: 'Unité de durée',
    EditCommodityComplianceProposalModal_Total_Cost_Title: 'Coût total',
    EditCommodityComplianceProposalModal_Currency: 'Devise',
    EditCommodityComplianceProposalModal_Payment_Plan_Title: 'Plan de paiement',
    // EditRequestCommodityComplianceModal
    EditRequestCommodityComplianceModal_Title: 'Mettre à jour la demande de conformité des produits',
    EditRequestCommodityComplianceModal_Descriptions_Title: 'Descriptions (multi-langues)',
    EditRequestCommodityComplianceModal_Button_Update: 'Enregistrer les mises à jour',
    // NewCommodityComplianceProposalModal
    NewCommodityComplianceProposalModal_Title: 'Nouvelle proposition',
    NewCommodityComplianceProposalModal_Descriptions_Title: 'Descriptions (multi-langues)',
    NewCommodityComplianceProposalModal_Tasks_Title: 'Liste des tâches',
    NewCommodityComplianceProposalModal_Duration: 'Durée',
    NewCommodityComplianceProposalModal_Duration_Unit: 'Unité de durée',
    NewCommodityComplianceProposalModal_Total_Cost_Title: 'Coût total',
    NewCommodityComplianceProposalModal_Currency: 'Devise',
    NewCommodityComplianceProposalModal_Payment_Plan_Title: 'Plan de paiement',
    NewCommodityComplianceProposalModal_Button_Create: 'Soumettre la proposition',
    // NEWCANDIDATEREQUEST MODAL
    FormNewCandidateRequestModal_Title: 'Nouvelle demande de candidat',
    FormNewCandidateRequestModal_Descriptions_Title: 'Descriptions (multi-langues)',
    FormNewCandidateRequestModal_Total_Cost_Title: 'Coût',
    FormNewCandidateRequestModal_Currency: 'Devise',
    FormNewCandidateRequestModal_Button_Create: 'Soumettre la demande',
    // EDITCANDIDATEREQUEST MODAL
    FormEditCandidateRequestModal_Title: 'Modifier la demande de candidat',
    FormEditCandidateRequestModal_Descriptions_Title: 'Descriptions (multi-langues)',
    FormEditCandidateRequestModal_Total_Cost_Title: 'Coût',
    FormEditCandidateRequestModal_Currency: 'Devise',
    // NEWCANDIDATEPROPOSAL MODAL
    FormNewCandidateProposalModal_Title: 'Nouvelle proposition de candidat',
    FormNewCandidateProposalModal_Descriptions_Title: 'Descriptions (multi-langues)',
    FormNewCandidateProposalModal_Candidate: 'Candidat',
    FormNewCandidateProposalModal_Button_Create: 'Soumettre la proposition',
    // EDITCANDIDATEPROPOSAL MODAL
    FormEditCandidateProposalModal_Title: 'Modifier la proposition de candidat',
    FormEditCandidateProposalModal_Descriptions_Title: 'Descriptions (multi-langues)',

    // DISCOVERING PAGE
    // PAGE DE DÉCOUVERTE
    Discovering_Meta_Title: 'Le e-commerce en Afrique désormais simple, fiable et mondial',
    Discovering_Title: 'Le e-commerce en Afrique désormais simple, fiable et mondial',
    Discovering_About: 'En savoir plus sur Lilenek.com',
    Discovering_Most_Search_1: 'Les recherches fréquentes',
    Discovering_Most_Search_2: 'Machines agroalimentaires',
    Discovering_Most_Search_3: 'Matières premières pour la cosmétique',
    Discovering_Most_Search_4: 'Auditeur de qualité',
    Discovering_Benefits_1: 'Large sélection de solutions commerciales',
    Discovering_Benefits_2: 'Explorez une gamme diversifiée de produits, services et fournisseurs adaptés aux besoins de votre entreprise. Avec des millions d’offres disponibles sur les marchés africains et mondiaux, notre plateforme vous connecte aux bonnes solutions pour développer votre entreprise sans effort.',
    Discovering_Benefits_3: 'Qualité garantie et transactions sécurisées',
    Discovering_Benefits_4: 'Travaillez avec des fournisseurs vérifiés pour garantir une production de haute qualité tout en bénéficiant d’une protection totale des transactions. Du paiement à la livraison, notre plateforme garantit la sécurité de vos commandes, vous offrant une tranquillité d’esprit lors de vos échanges locaux et internationaux.',
    Discovering_Benefits_5: 'Solution commerciale complète clé en main',
    Discovering_Benefits_6: "Simplifiez votre processus commercial avec une solution tout-en-un. De la recherche de produits et de fournisseurs à la gestion des commandes, des paiements et de l'exécution, notre plateforme rationalise chaque étape pour soutenir le commerce transfrontalier et intra-africain sans faille.",
    Discovering_Benefits_7: 'Expérience commerciale personnalisée',
    Discovering_Benefits_8: 'Accédez à des avantages personnalisés tels que des réductions exclusives, une protection renforcée et un soutien dédié, conçus pour accélérer la croissance de votre entreprise sur les marchés africains et mondiaux. Cette approche personnalisée garantit votre succès à chaque étape du parcours commercial.',
    Discovering_Exploring_1: 'Découvrez des millions d’offres adaptées aux besoins de votre entreprise',
    Discovering_Exploring_2: 'articles',
    Discovering_Exploring_3: 'fournisseurs',
    Discovering_Exploring_4: 'catégories d’articles',
    Discovering_Exploring_5: 'pays et régions',
    Discovering_Opportunity_1: 'Découvrez vos prochaines opportunités commerciale',
    Discovering_Opportunity_2: 'part',
    Discovering_Sourcing_1: 'Sourcez directement auprès des fabricants',
    Discovering_Sourcing_2: 'Obtenez des échantillons',
    Discovering_Sourcing_3: 'Assistez à des visites en direct d’usines',
    Discovering_Sourcing_4: 'Connectez-vous avec des fabricants de premier plan',
    Discovering_Sourcing_5: 'Filtrez les fournisseurs selon vos critères',
    Discovering_Sourcing_6: 'Voir plus',
    Discovering_Purchase_1: 'Commercez en toute confiance de la qualité de production à la protection des achats',
    Discovering_Purchase_2: 'Assurez-vous de la qualité de production avec les fournisseurs',
    Discovering_Purchase_3: ' ',
    Discovering_Purchase_4: "Collaborer avec un large éventail de fournisseurs disposant d'informations d'identification vérifiées par des tiers et de capacités éprouvées. Recherchez le",
    Discovering_Purchase_5: 'Vérifié',
    Discovering_Purchase_6: 'logo pour acheter des produits auprès de fournisseurs expérimentés en qui vous pouvez avoir confiance pour les besoins de votre entreprise.',
    Discovering_Purchase_7: 'Voir la vidéo',
    Discovering_Purchase_8: 'Payez vos achats avec',
    Discovering_Purchase_9: 'Commercez en toute confiance sur Lilenek, où des options de paiement sécurisées, une protection complète contre les problèmes de produit et d’expédition, et un soutien en médiation sont fournis pour toutes les préoccupations liées aux achats. Profitez d’une tranquillité d’esprit pour chaque commande et paiement.',
    Discovering_Streamline_1: 'Optimisez vos achats, de la recherche à la réalisation, le tout en un seul endroit',
    Discovering_Streamline_2: 'Recherchez vos besoins',
    Discovering_Streamline_3: 'Recherchez et filtrez facilement parmi des millions d’offres de produits, services et de fournisseurs pour identifier les meilleures options pour les besoins de votre entreprise. Notre plateforme vous aide à trouver rapidement et efficacement les bonnes solutions.',
    Discovering_Streamline_4: 'Identifiez le bon choix',
    Discovering_Streamline_5: 'Évaluez facilement la qualité des produits et les capacités des fournisseurs avec des inspections vérifiées et des outils de sourcing numériques avancés. Notre plateforme simplifie le processus d’évaluation pour vous aider à prendre des décisions éclairées rapidement et efficacement.',
    Discovering_Streamline_6: 'Payez en toute confiance',
    Discovering_Streamline_7: 'Complétez vos transactions en toute sécurité dans plus de 50 devises en utilisant plus de 15 méthodes de paiement de confiance, avec des options de termes de paiement flexibles. Profitez d’une expérience de paiement fluide et protégée pour toutes vos commandes.',
    Discovering_Streamline_8: 'Livraison avec transparence',
    Discovering_Streamline_9: 'Répondez à vos besoins logistiques avec des solutions personnalisées de Menelinks (services logistiques Lilenek). Bénéficiez du suivi en temps réel sur plus de 26 000 routes dans 220 pays et régions, le tout propulsé par Lilenek pour une visibilité et un contrôle complets.',
    Discovering_Streamline_10: 'Gérez avec facilité',
    Discovering_Streamline_11: 'Surveillez facilement l’état des commandes, gérez les fournisseurs, suivez les paiements et les expéditions, et accédez au support après-vente, le tout via le système de support intégré de Lilenek.',
    Discovering_Discounted_1: 'Découvrez des réductions personnalisées, des services et des outils pour toutes les tailles d’entreprise',
    Discovering_Discounted_2: 'Découvrez des avantages sélectionnés sur Lilenek, des ressources essentielles pour les petites entreprises aux outils et solutions avancés pour les grandes entreprises gérant des commandes complexes. Profitez d’un soutien personnalisé pour aider votre entreprise à croître à chaque étape, tout disponible gratuitement.',
    Discovering_Discounted_3: 'PDG de Mensah Organic Farms',
    Discovering_Discounted_4: 'En tant qu’entrepreneur dans l’industrie agroalimentaire, j’ai toujours été passionné par la culture de produits biologiques de haute qualité. Lilenek a été un partenaire clé pour concrétiser ma vision.',
    Discovering_Discounted_5: 'Fondatrice de Pure Skin Naturals',
    Discovering_Discounted_6: 'Étant profondément engagé dans la fabrication de cosmétiques écologiques, j’avais besoin d’un partenaire fiable. Lilenek a été déterminant pour m’aider à offrir des produits propres et efficaces à mes clients.',
    Discovering_Discounted_7: 'Co-fondateur d’ExploreIt Travel',
    Discovering_Discounted_8: 'Dans l’industrie du tourisme, créer des expériences mémorables est essentiel. Lilenek nous a soutenus à chaque étape pour construire des forfaits de voyage uniques pour nos clients.',
    Discovering_Discounted_9: 'Fondatrice de Okoye Builders',
    Discovering_Discounted_10: 'En tant que propriétaire d’une entreprise de construction, j’ai toujours été axé sur la construction de structures solides et fiables. Lilenek a été mon partenaire de choix, nous aidant à mener chaque projet à bien avec précision et qualité.',
    Discovering_Discounted_11: 'Découvrez-les',
    Discovering_Get_Started_1: 'Prêt à commencer ?',
    Discovering_Get_Started_2: 'Découvrez des millions d’opportunités auprès de fournisseurs de confiance en vous inscrivant dès aujourd’hui !',
    Discovering_Empowering_1: 'Autonomiser les entreprises grâce au commerce intra-africain et mondial',
    Discovering_Empowering_2: 'Lilenek propose des solutions de trading B2B2C tout-en-un pour les petites et moyennes entreprises mondiales, les aidant à se transformer grâce au commerce numérique, à saisir les opportunités et à accélérer leur croissance à l’international.',
    Discovering_Empowering_3: 'Notre mission',
    Discovering_Empowering_4: "Faciliter le business sur l'ensemble du marché africain, depuis n'importe où.",
    Discovering_Empowering_5: 'Nos emplacements',
    Discovering_Empowering_6: 'Nous avons des équipes à travers le monde.',
    Discovering_Empowering_7: 'Nos engagements ESG',
    Discovering_Empowering_8: "Technologie responsable, avenir durable & application d'économies circulaires",
    Discovering_Find_Suppliers_1: 'Trouvez des fournisseurs par pays ou région',
    Discovering_Search_0: 'Recherche',
    Discovering_Search_1: 'Panneaux solaires',
    Discovering_Search_2: 'Engrais bio',
    Discovering_Search_3: 'Machine de production agricole',
    Discovering_Search_4: 'Service de conciergerie',
    Discovering_Search_5: 'Beurre de karité pur',
    Discovering_Search_6: 'Service de garderie',
    Discovering_Testimonials_1: 'Ils vous racontent leur experience',

    NewSponsorshipModal_Descriptions_Title: 'Descriptions',
    NewSponsorshipModal_Price_Title: 'Prix du parrainage',
    NewSponsorshipModal_Price_Value_1: 'Prix',
    NewSponsorshipModal_Price_Currency: 'Devise',
    NewSponsorshipModal_Button_Create: 'Soumettre parrainage',

    NewSponsorshipProposalModal_Descriptions_Title: 'Descriptions',
    NewSponsorshipProposalModal_Price_Title: 'Prix de la proposition',
    NewSponsorshipProposalModal_Price_Value_1: 'Prix',
    NewSponsorshipProposalModal_Price_Currency: 'Devise',
    NewSponsorshipProposalModal_Button_Create: 'Soumettre proposition',

    ViewSponsorshipProposalModal_Proposed_Sponsorship: 'Sponsorship proposé',
    ViewSponsorshipProposalModal_Payment_Plan_1: 'Vous recevrez',
    ViewSponsorshipProposalModal_Payment_Plan_2: 'où le premier sera le',
    ViewSponsorshipProposalModal_Payment_Plan_3: 'et le dernier sera le',
    ViewSponsorshipProposalModal_Button_Accept: 'Accepter',
    ViewSponsorshipProposalModal_Button_Reject: 'Rejeter',
    ViewSponsorshipProposalModal_Response_1: 'Voulez-vous vraiment accepter cette proposition?',
    ViewSponsorshipProposalModal_Response_2: 'Voulez-vous vraiment rejeter cette proposition?',

    AddSponsorshipNoteModal_Title: 'Add final note',
    AddSponsorshipNoteModal_Button_Create: 'Soumettre la note',

    WishWesh_Title: 'Fais un vœu, on pourrait l’exaucer',
    WishWesh_Comment_Placeholder: 'veux...',
    WishWesh_Loading: 'Transmission de votre souhait...',
    WishWesh_No_More_Wishes: 'Vous avez fait tous vos souhaits quotidiens, réessayez demain',

    Categories_Nb_Categories_1: 'Catégories',
    Categories_Nb_Categories_2: 'Catégorie',
    Categories_Nb_Countries_1: 'Pays',
    Categories_Nb_Countries_2: 'Pays',
  },
};

{
  /* <FormattedMessage id="" />&nbsp; */
}
